import { mxCell, mxGraph } from '@anekonnect/mxgraph';
import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import svg64 from 'svg64';

import { getImageDimension, isSquare } from '../Helper';

import { mx } from '~/constants/wizard';
import {
  wizardSetLogoDimesion,
  wizardSetTemplateLogo,
  wizardSetTemplateSVG,
} from '~/store/actions/wizard/Action';
import { AppState } from '~/store/reducers';
import { BaseSize, ListComponent } from '~/store/reducers/wizard/State';
import { PaperSizeData, paperSizeData as defaultPaperSizeData } from '~/utils/paperSize';
import { useAppSelector } from '~/store/hooks';

type UserData = {
  data?: {
    template?: {
      url: string;
    };
    logo?: {
      url: string;
    };
  };
};

type PropsFromState = {
  whoami: UserData;
  user: UserData;
  templateSVG: string;
  templateLogo: string;
  paperSize: string;
  components: ListComponent;
  paperSizeData: PaperSizeData;
  logoDimension: BaseSize;
  assemblyStatus: string;
  menuViewActiveList: string[];
};

type PropsFromDispatch = {
  wizardSetTemplateSVG: typeof wizardSetTemplateSVG;
  wizardSetTemplateLogo: typeof wizardSetTemplateLogo;
  wizardSetLogoDimesion: typeof wizardSetLogoDimesion;
};

type DrawProps = {
  graph: mxGraph;
} & PropsFromState &
  PropsFromDispatch;

const Draw = ({
  graph,
  whoami,
  user,
  wizardSetTemplateSVG,
  templateSVG,
  templateLogo,
  wizardSetTemplateLogo,
  paperSize,
  components,
  paperSizeData,
  logoDimension,
  wizardSetLogoDimesion,
  assemblyStatus,
  menuViewActiveList,
}: DrawProps) => {
  const tenantConfig = useAppSelector((state) => state.data.tenantConfig);
  const { isTenant, data } = tenantConfig;
  const UIConfig = data?.ui_config;

  const graphClear = useCallback(() => {
    graph.getModel().clear();
  }, [graph]);

  useEffect(graphClear, [graphClear]);

  const getTemplate = useCallback(
    (template: string) => {
      fetch(template)
        .then((response) => response.text())
        .then((data) => {
          const base64 = svg64(data);
          wizardSetTemplateSVG(base64);
        });
    },
    [wizardSetTemplateSVG],
  );

  const toDataURL = (url: string, callback: (...args: any) => any) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      const reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  };

  const getLogo = useCallback(
    (url: string) => {
      toDataURL(url, (dataUrl: string) => {
        wizardSetTemplateLogo(dataUrl.replace(';base64', ''));
      });
    },
    [wizardSetTemplateLogo],
  );

  const setTemplateAndLogo = useCallback(() => {
    let template = UIConfig?.template_url || '/img/default-template.svg';
    let templateLogo = UIConfig?.logo_url || '/img/logo.png';

    if (user.data) {
      if (user.data.template?.url !== undefined) {
        template = user.data.template.url;
      }

      if (user.data.logo?.url !== undefined) {
        templateLogo = user.data.logo.url;

        getImageDimension(templateLogo).then((dimension) =>
          wizardSetLogoDimesion({ width: dimension.width, height: dimension.height }),
        );
      }
    } else {
      if (whoami.data) {
        if (whoami.data.template?.url !== undefined) {
          template = whoami.data.template.url;
        }

        if (whoami.data.logo?.url !== undefined) {
          templateLogo = whoami.data.logo.url;

          getImageDimension(templateLogo).then((dimension) =>
            wizardSetLogoDimesion({ width: dimension.width, height: dimension.height }),
          );
        }
      }
    }

    getTemplate(template);
    getLogo(templateLogo);
  }, [
    UIConfig?.logo_url,
    UIConfig?.template_url,
    getLogo,
    getTemplate,
    user.data,
    whoami.data,
    wizardSetLogoDimesion,
  ]);

  useEffect(setTemplateAndLogo, [setTemplateAndLogo]);

  const addTemplate = useCallback(() => {
    if (!templateSVG || !templateLogo) {
      return;
    }

    if (graph.getBackgroundImages().length > 0) {
      graph.setBackgroundImages([]);
      graph.refresh();
    }

    const template = new mx.mxImage(
      templateSVG,
      graph.pageFormat.width,
      graph.pageFormat.height,
      0,
      0,
    );

    if (menuViewActiveList.includes('template')) {
      graph.setBackgroundImages([template]);
      graph.refresh();
    }

    const pageWidth = graph.pageFormat.width;
    const pageHeight = graph.pageFormat.height;
    const templateLogoStyle = `shape=image;imageAspect=0;aspect=fixed;verticalLabelPosition=bottom;verticalAlign=top;rounded=1;shadow=0;comic=0;fontFamily=Segoe UI;fontSize=12;strokeWidth=1;image=${templateLogo}`;
    const templateTitleBlockStyle =
      'text;html=1;strokeColor=none;fillColor=none;align=center;verticalAlign=middle;whiteSpace=wrap;rounded=0;shadow=0;comic=0;fontFamily=Segoe UI;fontSize=12;';
    const listOfTitleBlockType: { [key: string]: mxCell } = {};

    const cells: mxCell[] = Object.values(graph.model.cells);

    if (components && cells && cells.length > 0) {
      const listX = [pageWidth];
      const listY = [pageHeight];

      cells.forEach((cell) => {
        const isTemplateLogo = cell.id.includes('template_logo_page');
        const isWatermark = cell.id.includes('template_watermark_page');
        const isTemplateTitleBlock = cell.id.includes('template_title-block');

        if (isTemplateLogo || isWatermark) {
          graph.getModel().remove(cell);
        } else if (isTemplateTitleBlock) {
          const id = cell.id.split('_');
          const typeObject = id[2];

          listOfTitleBlockType[typeObject] = cell;

          graph.getModel().remove(cell);
        } else {
          if (cell.geometry) {
            const x = cell.geometry.x + cell.geometry.width;
            const y = cell.geometry.y + cell.geometry.height;

            listX.push(x);
            listY.push(y);
          }
        }
      });

      const longestX = Math.max(...listX);
      const longestY = Math.max(...listY);

      const countHorizontalPage = Math.ceil(longestX / pageWidth);
      const countVerticalPage = Math.ceil(longestY / pageHeight);

      const parent = graph.getDefaultParent();

      graph.model.beginUpdate();

      const reDrawTitleBlockCell = (page: number, pageX: number, pageY: number) => {
        Object.keys(listOfTitleBlockType).forEach((type) => {
          const data = listOfTitleBlockType[type];
          const value = data.value;
          let x = 50;
          let y = 50;
          let width = 200;
          let height = 40;

          if (paperSizeData[paperSize].titleBlockGeometry[type]) {
            const geometry = paperSizeData[paperSize].titleBlockGeometry[type];
            x = geometry.x;
            y = geometry.y;
            width = geometry.width;
            height = geometry.height;
          }

          const templateTitleBlockName = `template_title-block_${type}_page_${page}`;
          const titleBlockX = x + pageX;
          const titleBlockY = y + pageY;
          const templateTitleBlockPerPage = graph.insertVertex(
            parent,
            templateTitleBlockName,
            value,
            titleBlockX,
            titleBlockY,
            width,
            height,
            data.style ? data.style : templateTitleBlockStyle,
          );
          templateTitleBlockPerPage.setConnectable(false);
        });
      };

      try {
        let page = 1;
        const newBackgroundImages = [];

        for (let verticalIndex = 0; verticalIndex < countVerticalPage; verticalIndex++) {
          for (let horizontalIndex = 0; horizontalIndex < countHorizontalPage; horizontalIndex++) {
            const pageX = pageWidth * horizontalIndex;
            const pageY = pageHeight * verticalIndex;

            if (menuViewActiveList.includes('template')) {
              const newBackgroundImage = mx.mxUtils.clone(template);
              newBackgroundImage.x = pageX;
              newBackgroundImage.y = pageY;
              newBackgroundImage.width = pageWidth;
              newBackgroundImage.height = pageHeight;
              newBackgroundImages.push(newBackgroundImage);
            }

            const templateLogoName = `template_logo_page_${page}`;
            const logoX = paperSizeData[paperSize].logoGeometry.x + pageX;
            const logoY = paperSizeData[paperSize].logoGeometry.y + pageY;

            const templateWatermarkName = `template_watermark_page_${page}`;
            const watermarkX = paperSizeData[paperSize].width / 2.2 + pageX;
            const watermarkY = paperSizeData[paperSize].height / 2.2 + pageY;

            let logoWidth = paperSizeData[paperSize].logoGeometry.width;
            let logoHeight = paperSizeData[paperSize].logoGeometry.height;
            const defaultLogoWidth = defaultPaperSizeData[paperSize].logoGeometry.width;
            const defaultLogoHeight = defaultPaperSizeData[paperSize].logoGeometry.height;
            const isLogoSquare = isSquare(logoDimension.width, logoDimension.height);
            const isLogoSettingSquare = isSquare(logoWidth, logoHeight);

            if (logoDimension.width > 0 && logoDimension.height > 0) {
              if (logoWidth === defaultLogoWidth || isLogoSquare) {
                if (!isLogoSettingSquare) {
                  logoWidth = logoDimension.width;
                }
              } else {
                if (isLogoSettingSquare && !isLogoSquare) {
                  logoWidth = logoDimension.width;
                }
              }

              if (logoHeight === defaultLogoHeight || isLogoSquare) {
                if (!isLogoSettingSquare) {
                  logoHeight = logoDimension.height;
                }
              } else {
                if (isLogoSettingSquare && !isLogoSquare) {
                  logoHeight = logoDimension.height;
                }
              }
            }

            const orderCells: mxCell[] = [];

            if (menuViewActiveList.includes('logo')) {
              const templateLogoPerPage = graph.insertVertex(
                parent,
                templateLogoName,
                null,
                logoX,
                logoY,
                logoWidth,
                logoHeight,
                templateLogoStyle,
              );
              templateLogoPerPage.setConnectable(false);

              orderCells.push(templateLogoPerPage);
            }

            if (isTenant && menuViewActiveList.includes('status-watermark')) {
              const templateWatermarkPerPage = graph.insertVertex(
                parent,
                templateWatermarkName,
                UIConfig?.assembly_status_mapping
                  ? UIConfig?.assembly_status_mapping[assemblyStatus] ||
                      assemblyStatus.toUpperCase()
                  : assemblyStatus.toUpperCase(),
                watermarkX,
                watermarkY,
                0,
                0,
                'rounded=1;fillStyle=auto;strokeColor=red;labelBackgroundColor=none;fontSize=100;verticalAlign=top;horizontal=1;fontColor=red;rotation=-30;strokeWidth=10;fillColor=none;glass=0;fixedWidth=0;cloneable=0;deletable=0;rotatable=0;pointerEvents=0;resizable=0;connectable=0;allowArrows=0;recursiveResize=0;expand=0;editable=1;movable=0;locked=0;opacity=15;textOpacity=15;',
              );

              orderCells.unshift(templateWatermarkPerPage);
            }

            if (orderCells.length > 0) {
              graph.orderCells(true, orderCells);
            }

            reDrawTitleBlockCell(page, pageX, pageY);

            page++;
          }
        }

        graph.setBackgroundImages(newBackgroundImages);
      } finally {
        graph.model.endUpdate();
      }
    }
  }, [
    UIConfig?.assembly_status_mapping,
    assemblyStatus,
    components,
    graph,
    isTenant,
    logoDimension.height,
    logoDimension.width,
    menuViewActiveList,
    paperSize,
    paperSizeData,
    templateLogo,
    templateSVG,
  ]);

  useEffect(addTemplate, [addTemplate]);

  return <React.Fragment />;
};

const mapStateToProps = (state: AppState) => {
  return {
    whoami: state.data.whoami,
    user: state.data.user,
    templateSVG: state.assemblyWizard.templateSVG,
    templateLogo: state.assemblyWizard.templateLogo,
    paperSize: state.assemblyWizard.paperSize,
    components: state.assemblyWizard.components,
    paperSizeData: state.assemblyWizard.paperSizeData,
    logoDimension: state.assemblyWizard.logoDimension,
    assemblyStatus: state.assemblyWizard.status,
    menuViewActiveList: state.assemblyWizard.menuViewActiveList,
  };
};

const mapDispatchToProps = {
  wizardSetTemplateSVG,
  wizardSetTemplateLogo,
  wizardSetLogoDimesion,
};

export default connect(mapStateToProps, mapDispatchToProps)(Draw);
