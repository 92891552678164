import { ExclamationCircleFilled, ShareAltOutlined } from '@ant-design/icons';
import { Dropdown, MenuProps, message, Modal, Space } from 'antd';
import { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { deleteCancelReview } from '~/api/AuthorizedDeletes';
import { getDataAssembliesItem } from '~/api/AuthorizedGets';

// import { ShareModal } from '~/components/Modals';
import RFAModal from '~/components/Modals/RFAModal';
import { wizardLoadAssemblyData } from '~/store/actions/wizard/Action';
import { useAppDispatch, useAppSelector } from '~/store/hooks';
import { AppState } from '~/store/reducers';
import { AuthState } from '~/store/reducers/data/Auth';
import MenuButton from './MenuButton';
// import { TenantShareModal } from '~/components/Modals/TenantShareModal';
import { RevisedShareModal } from '~/components/Modals/RevisedShareModal';

type PropsFromState = {
  auth: AuthState;
  assemblyId?: number;
  assemblyStatus: string;
};

type MenuShareProps = PropsFromState;

const MenuShare = ({ auth, assemblyId, assemblyStatus }: MenuShareProps) => {
  const dispatch = useAppDispatch();
  const { isTenant } = useAppSelector((state: AppState) => state.data.tenantConfig);
  const assemblyWizard = useAppSelector((state) => state.assemblyWizard);
  const whoami = useAppSelector((state) => state.data.whoami.data);

  const { createdBy } = assemblyWizard;
  // const { id: loginid } = whoami;
  const [menuItems, setMenuItems] = useState<MenuProps['items']>([]);
  const [openShareModal, setOpenShareModal] = useState(false);
  // const [receiversMessage, setReceiversMessage] = useState<string>('');
  const [openRFAModal, setOpenRFAModal] = useState(false);

  const handleOpenShareModal = () => {
    setOpenShareModal(true);
  };

  const handleCloseShareModal = () => {
    setOpenShareModal(false);
  };
  // console.log(createdBy, whoami?.id, 'check owner of assemblies');

  useEffect(() => {
    const items: MenuProps['items'] = [
      {
        key: 'via-link',
        disabled: !assemblyId || createdBy !== whoami?.id,
        onClick: handleOpenShareModal,
        label: <Fragment>Via {auth.authenticated ? 'Email' : 'Link'}</Fragment>,
      },
    ];

    if (
      isTenant &&
      assemblyId &&
      (assemblyStatus.toLowerCase() === 'draft' || assemblyStatus.toLowerCase() === 'rejected')
    ) {
      items.push({
        key: 'rfa',
        disabled: !assemblyId,
        onClick: () => setOpenRFAModal(true),
        label: <Fragment>Route for Approval</Fragment>,
      });
    }

    if (isTenant && assemblyId && assemblyStatus.toLowerCase() === 'under review') {
      items.push({
        key: 'ca',
        disabled: !assemblyId,
        onClick: () =>
          Modal.confirm({
            title: 'Are you sure you want to cancel this Route for Approval?',
            icon: <ExclamationCircleFilled />,
            content: 'This action cannot be undone. But you can create a new Route for Approval.',
            async onOk() {
              try {
                const response = await deleteCancelReview(`${assemblyId}/review`);

                if (response) {
                  const data = await getDataAssembliesItem(String(assemblyId));

                  dispatch(wizardLoadAssemblyData(data));

                  message.success('RFA cancelled successfully');
                }
              } catch {
                return message.error('Something went wrong!');
              }
            },
          }),
        label: <Fragment>Cancel Route</Fragment>,
      });
    }

    setMenuItems(items);
  }, [assemblyId, assemblyStatus, auth.authenticated, dispatch, isTenant, createdBy, whoami?.id]);

  return (
    <>
      <RevisedShareModal
        isTenant={isTenant || false}
        isAuthenticated={auth.authenticated}
        isOpen={openShareModal}
        onCancel={handleCloseShareModal}
        assemblyId={assemblyId}
      />
      {/* {isTenant === true ? (
        <TenantShareModal
          emails={emails}
          isAuthenticated={auth.authenticated}
          isOpen={openShareModal}
          onCancel={handleCloseShareModal}
          onEmailsChange={setEmails}
          onMessageChange={setReceiversMessage}
          onOK={handleShareAction}
        />
      ) : (
        <ShareModal
          emails={emails}
          isAuthenticated={auth.authenticated}
          isOpen={openShareModal}
          onCancel={handleCloseShareModal}
          onEmailsChange={setEmails}
          onMessageChange={setReceiversMessage}
          onOK={handleShareAction}
        />
      )} */}
      <RFAModal isOpen={openRFAModal} onClose={() => setOpenRFAModal(false)} />
      <Dropdown menu={{ items: menuItems }} trigger={['click']}>
        <Space>
          <MenuButton icon={<ShareAltOutlined />} label="Share" />
        </Space>
      </Dropdown>
    </>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    auth: state.data.auth,
    assemblyId: state.assemblyWizard.id,
    assemblyStatus: state.assemblyWizard.status,
  };
};

export default connect(mapStateToProps)(MenuShare);
