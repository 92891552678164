import { mxCell, mxGraph, StyleMap } from '@anekonnect/mxgraph';
import React, { useCallback, useEffect } from 'react';

import { ComponentData, Ref } from '../../Types';

import Constant from './Constant';

import { mx } from '~/constants/wizard';
import { ObjectShape } from '~/store/reducers/configs';
import useGetClickedPostion from '~/hooks/useGetClickedPostion';

type DrawProps = {
  graph: mxGraph;
  defaultStylesRef: Ref<StyleMap | undefined>;
  data: ComponentData;
  objects: ObjectShape[];
};

const { mxClient, mxUtils, mxPoint } = mx;

const Draw = (props: DrawProps) => {
  const { graph, defaultStylesRef, data, objects } = props;

  const {
    defaultAlias,
    lengthOfAllItems,
    totalHeightOfContainer,
    hasShell,
    options: { schematics: schematicsSize },
    type,
    index,
    id,
    contact_details: contactDetails,
  } = data;

  const clickedPosition = useGetClickedPostion();

  const drawPenetrator = useCallback(
    (parent: mxCell) => {
      objects.forEach((object) => {
        const configs = object.configs;
        const rootId = `${type}_schematics_${id}_${index}_draw`;
        const parentId = `${rootId}_container_parent`;
        const startValue = String(lengthOfAllItems - lengthOfAllItems + 1);
        const labelStyle = `fontSize=10;verticalLabelPosition=middle;labelBackgroundColor=white;labelPadding=1;indicatorSpacing=20px`;

        if (graph.getModel().getCell(parentId)) {
          return;
        }

        const doc = mx.mxUtils.createXmlDocument();
        const node = doc.createElement('component');
        node.setAttribute('label', defaultAlias);
        node.setAttribute('configs', JSON.stringify(configs));

        /** CREATE PENETRATOR CONTAINER */
        const penetratorContainer = graph.insertVertex(
          parent,
          parentId,
          node,
          clickedPosition.x,
          clickedPosition.y + 140,
          schematicsSize.width * 3 - schematicsSize.width / 2,
          totalHeightOfContainer >= 600 ? 600 : totalHeightOfContainer,
          // @see: https://jgraph.github.io/mxgraph/docs/js-api/files/util/mxConstants-js.html#mxConstants
          `shadow=1;fillColor=#fff;strokeColor=#000;strokeWidth=1;`,
        );
        penetratorContainer.setConnectable(false);
        penetratorContainer.geometry.height = totalHeightOfContainer;
        penetratorContainer.geometry.offset = new mxPoint(
          0,
          -penetratorContainer.geometry.height / 2 - 27,
        );
        /** END OF PENETRATOR CONTAINER */

        /** CREATE PENETRATOR PIN */
        const penetratorPinRootLeft = graph.insertVertex(
          penetratorContainer,
          `${rootId}_${Constant.penetratorPinId}_left_${startValue}`,
          contactDetails?.length > 0 ? contactDetails[0].contact_representation : startValue,
          0,
          0,
          schematicsSize.width,
          schematicsSize.height,
          `fillColor=#f0f8ff;rounded=0;rotatable=0;`,
        );

        penetratorPinRootLeft.geometry.relative = true;
        penetratorPinRootLeft.geometry.offset = new mxPoint(0, hasShell ? 10 : 0);
        penetratorPinRootLeft.setConnectable(false);
        penetratorPinRootLeft.getParent().setStyle('resizable=0');

        const penetratorPinRootCenter = graph.insertVertex(
          penetratorContainer,
          `${rootId}_penetrator_center_${startValue}`,
          '',
          0,
          0,
          schematicsSize.width / 2,
          schematicsSize.height,
          `fillColor=#000;rounded=0;rotatable=0;`,
        );

        penetratorPinRootCenter.geometry.relative = true;
        penetratorPinRootCenter.geometry.offset = new mxPoint(
          0 + schematicsSize.width,
          hasShell ? 10 : 0,
        );
        penetratorPinRootCenter.setConnectable(false);
        penetratorPinRootCenter.getParent().setStyle('resizable=0;');

        const penetratorPinRootRight = graph.insertVertex(
          penetratorContainer,
          `${rootId}_${Constant.penetratorPinId}_right_${startValue}`,
          contactDetails?.length > 0 ? contactDetails[0].contact_representation : startValue,
          0,
          0,
          schematicsSize.width,
          schematicsSize.height,
          `fillColor=#f0f8ff;rounded=0;rotatable=0;`,
        );

        penetratorPinRootRight.geometry.relative = true;
        penetratorPinRootRight.geometry.offset = new mxPoint(
          0 + schematicsSize.width + schematicsSize.width / 2,
          hasShell ? 10 : 0,
        );
        penetratorPinRootRight.setConnectable(false);
        penetratorPinRootRight.getParent().setStyle('resizable=0;');

        // LOOP PENETRATOR PIN
        for (let i = +startValue; i < lengthOfAllItems; i++) {
          const connectorPointNextLeft = penetratorPinRootLeft.clone();

          if (contactDetails?.length > 0) {
            const contactDetail = contactDetails[i];
            if (contactDetail) {
              connectorPointNextLeft.setValue(contactDetail.contact_representation);
            } else {
              connectorPointNextLeft.setValue(`${i + 1}`);
            }
          } else {
            connectorPointNextLeft.setValue(`${i + 1}`);
          }

          connectorPointNextLeft.setId(`${rootId}_${Constant.penetratorPinId}_left_${i + 1}`);
          connectorPointNextLeft.geometry.offset = new mxPoint(
            0,
            i * (Constant.penetratorHeight - 10) + (hasShell ? 10 : 0),
          );
          penetratorContainer.insert(connectorPointNextLeft, i);

          const connectorPointNextCenter = penetratorPinRootCenter.clone();
          connectorPointNextCenter.setId(`${rootId}_penetrator_center_${i + 1}`);
          connectorPointNextCenter.geometry.offset = new mxPoint(
            0 + schematicsSize.width,
            i * (Constant.penetratorHeight - 10) + (hasShell ? 10 : 0),
          );
          penetratorContainer.insert(connectorPointNextCenter, i);

          const connectorPointNextRight = penetratorPinRootRight.clone();
          connectorPointNextRight.setId(`${rootId}_${Constant.penetratorPinId}_right_${i + 1}`);

          if (contactDetails?.length > 0) {
            const contactDetail = contactDetails[i];
            if (contactDetail) {
              connectorPointNextRight.setValue(contactDetail.contact_representation);
            } else {
              connectorPointNextRight.setValue(`${i + 1}`);
            }
          } else {
            connectorPointNextRight.setValue(`${i + 1}`);
          }

          connectorPointNextRight.geometry.offset = new mxPoint(
            0 + schematicsSize.width + schematicsSize.width / 2,
            i * (Constant.penetratorHeight - 10) + (hasShell ? 10 : 0),
          );
          penetratorContainer.insert(connectorPointNextRight, i);
        }
        /** END OF CREATE PENETRATOR PIN */

        /** CREATE PENETRATOR PORT */
        // PORT LEFT
        const portLeftStyle = `shape=line;align=right;verticalAlign=middle;routingCenterX=${Constant.endTerminalPointStart};spacingRight=9;fontColor=${Constant.baseColor};strokeColor=${Constant.baseColor};${labelStyle};rotatable=0;`;
        const portLeftRoot = graph.insertVertex(
          penetratorContainer,
          `${rootId}_${Constant.portLeftId}_${+startValue}`,
          contactDetails && contactDetails[0]?.annotation_position !== 'right'
            ? contactDetails[0]?.contact_prefilled_annotation || null
            : null,
          0,
          0,
          Constant.portWidth,
          Constant.portHeight,
          portLeftStyle,
        );
        portLeftRoot.geometry.relative = true;
        portLeftRoot.geometry.offset = new mxPoint(
          0 - Constant.portWidth,
          penetratorPinRootLeft.geometry.height / 2 - (hasShell ? 0 : 7) + 2,
        );
        portLeftRoot.setConnectable(true);

        // PORT RIGHT
        const portRightStyle = `shape=line;align=left;verticalAlign=middle;routingCenterX=${Constant.endTerminalPointEnd};spacingLeft=9;fontColor=${Constant.baseColor};strokeColor=${Constant.baseColor};${labelStyle};rotatable=0;`;
        const portRightRoot = graph.insertVertex(
          penetratorContainer,
          `${rootId}_${Constant.portRightId}_${+startValue}`,
          contactDetails && contactDetails[0]?.annotation_position === 'right'
            ? contactDetails[0]?.contact_prefilled_annotation || null
            : null,
          0,
          0,
          Constant.portWidth,
          Constant.portHeight,
          portRightStyle,
        );
        portRightRoot.geometry.relative = true;
        portRightRoot.geometry.offset = new mxPoint(
          0 + schematicsSize.width * 2 + schematicsSize.width / 2,
          penetratorPinRootRight.geometry.height / 2 - (hasShell ? 0 : 7) + 2,
        );
        portRightRoot.setConnectable(true);

        // PORT TOP & PORT BOTTOM
        // Only create port top and bottom when hasShell true
        if (hasShell) {
          const portTopRoot = graph.insertVertex(
            penetratorContainer,
            `${rootId}_${Constant.portTopId}_${+startValue}`,
            null,
            0,
            0,
            0.1,
            10,
            `align=center;rounded=0;fontColor=#000;strokeColor=#000;dashed=0;routingCenterY=${Constant.endTerminalPointStart};rotatable=0;`,
          );
          portTopRoot.geometry.relative = true;
          portTopRoot.geometry.offset = new mxPoint(
            0 + schematicsSize.width + schematicsSize.width / 4,
            -Math.abs(penetratorPinRootCenter.geometry.offset.y - 10 + 10),
          );
          portTopRoot.setAttribute(Constant.portTopId, 1);
          portTopRoot.setConnectable(true);

          const portBottomRoot = graph.insertVertex(
            penetratorContainer,
            `${rootId}_${Constant.portBottomId}_0`,
            null,
            0,
            0,
            0.1,
            10,
            `align=center;rounded=0;fontColor=#000;strokeColor=#000;dashed=0;routingCenterY=${Constant.endTerminalPointEnd};rotatable=0;`,
          );
          portBottomRoot.geometry.relative = true;
          portBottomRoot.geometry.offset = new mxPoint(
            0 + schematicsSize.width + schematicsSize.width / 4,
            penetratorContainer.geometry.height,
          );
          portBottomRoot.setConnectable(true);
        }

        // LOOP PENETRATOR PORT
        for (let i = +startValue; i < lengthOfAllItems; i++) {
          const startConnectorPosition = i * penetratorPinRootLeft.geometry.height + 2;
          const connectorPositionCenter =
            penetratorPinRootLeft.geometry.height / 2 - (hasShell ? 0 : 10);

          const portLeftNext = graph.insertVertex(
            penetratorContainer,
            `${rootId}_${Constant.portLeftId}_${i + 1}`,
            contactDetails && contactDetails[i]?.annotation_position !== 'right'
              ? contactDetails[i]?.contact_prefilled_annotation || null
              : null,
            0,
            0,
            Constant.portWidth,
            Constant.portHeight,
            portLeftStyle,
          );
          portLeftNext.geometry.relative = true;
          portLeftNext.geometry.offset = new mxPoint(
            0 - Constant.portWidth,
            startConnectorPosition + connectorPositionCenter,
          );
          portLeftNext.setConnectable(true);

          const portRightNext = graph.insertVertex(
            penetratorContainer,
            `${rootId}_${Constant.portRightId}_${i + 1}`,
            contactDetails && contactDetails[i]?.annotation_position === 'right'
              ? contactDetails[i]?.contact_prefilled_annotation || null
              : null,
            0,
            0,
            Constant.portWidth,
            Constant.portHeight,
            portRightStyle,
          );
          portRightNext.geometry.relative = true;
          portRightNext.geometry.offset = new mxPoint(
            0 + schematicsSize.width * 2 + schematicsSize.width / 2,
            startConnectorPosition + connectorPositionCenter,
          );
          portRightNext.setConnectable(true);
        }
        /** END OF CREATE PENETRATOR PORT */

        /** MANAGE VERTEX STYLES IF HAS SHELL */
        const graphStyleSheet = graph.getStylesheet();

        if (!hasShell) {
          const defaultVertexStyle = graphStyleSheet.getDefaultVertexStyle();
          let newVertexStyle = graphStyleSheet.createDefaultVertexStyle();
          newVertexStyle = mxUtils.clone(defaultVertexStyle);
          newVertexStyle['strokeWidth'] = 0;
          newVertexStyle['strokeColor'] = 'none';
          newVertexStyle['rounded'] = '0';
          graphStyleSheet.putDefaultVertexStyle(newVertexStyle);
        } else {
          if (defaultStylesRef.current) {
            graphStyleSheet.putDefaultVertexStyle(defaultStylesRef.current);
          }
        }
        /** END OF MANAGE VERTEX STYLES IF HAS SHELL */
      });
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      objects,
      type,
      id,
      index,
      lengthOfAllItems,
      graph,
      defaultAlias,
      schematicsSize.width,
      schematicsSize.height,
      totalHeightOfContainer,
      contactDetails,
      hasShell,
      defaultStylesRef,
    ],
  );

  const setDefaultVertexStyle = useCallback(() => {
    defaultStylesRef.current = graph.getStylesheet().getDefaultVertexStyle();
  }, [graph, defaultStylesRef]);

  useEffect(setDefaultVertexStyle, [setDefaultVertexStyle]);

  const addConnector = useCallback(() => {
    const isHaveItems = lengthOfAllItems > 0;

    if (!mxClient.isBrowserSupported) {
      mxUtils.error('Browser is not supported!', 200, false);
    } else {
      const parent = graph.getDefaultParent();
      graph.getModel().beginUpdate();

      try {
        if (isHaveItems) {
          drawPenetrator(parent);
        }
      } finally {
        graph.getModel().endUpdate();
      }
    }
  }, [lengthOfAllItems, graph, drawPenetrator]);

  useEffect(addConnector, [addConnector]);

  return <React.Fragment />;
};

export default Draw;
