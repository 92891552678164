import React, { useEffect, useState } from 'react';
import { Table, Select, Button, message } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useAppSelector } from '~/store/hooks';
import { getSharedUserAssemblies } from '~/api/AuthorizedGets';
import styles from './Wizardsharedwith.module.scss';
import { postRemoveaccessAssembly, postShareAssembly } from '~/api/AuthorizedPosts';
import { defaultErrorHandler } from '~/api/HttpError';

const { Option } = Select;

interface DataType {
  id: string;
  email: string;
  username: string;
  permission: string;
  date: Date;
}

interface WizardSharedWithProps {
  activeTabKey: string;
}

const WizardSharedWith: React.FC<WizardSharedWithProps> = ({ activeTabKey }) => {
  const assemblyWizard = useAppSelector((state) => state.assemblyWizard);
  const whoami = useAppSelector((state) => state.data.whoami.data);

  const { id: assemblyId, createdBy } = assemblyWizard;
  const { id: loginid } = whoami;
  const [userdata, setuserdata] = useState<DataType[] | undefined>(undefined);

  // console.log(
  //   assemblyId,
  //   activeTabKey,
  //   'assemblies id',
  //   userdata,
  //   createdBy,
  //   '--login id-->',
  //   loginid,
  // );

  useEffect(() => {
    const getData = async (assemblyId: number) => {
      const response = await getSharedUserAssemblies(assemblyId);

      if (response?.users) {
        setuserdata(response?.users);
      }
    };

    if (assemblyId && activeTabKey === 'sharedWith') {
      getData(assemblyId);
    }
  }, [assemblyId, activeTabKey]);

  const handleRoleChange = async (value: string, record: DataType) => {
    let payload;
    if (value === 'read') {
      payload = { can_read: [record.id] };
    } else {
      payload = { can_write: [record.id] };
    }
    // console.log('Role changed', value, record, 'payload---->', payload);
    await postShareAssembly(payload, defaultErrorHandler, String(assemblyId)).then(() => {
      message.success('Access Changed Successfully');
    });
  };

  const handleRemoveAccess = async (record: DataType) => {
    const payload = { user_id: record.id };
    console.log('Remove access for', record);
    const updatedUserdata = userdata?.filter((item) => item.id !== record.id);
    await postRemoveaccessAssembly(payload, defaultErrorHandler, String(assemblyId)).then(() => {
      message.success('Access Removed Successfully');
      setuserdata(updatedUserdata);
    });
  };

  const columns: ColumnsType<DataType> = [
    {
      title: 'Email Address',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'User Name',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Access',
      key: 'permission',
      render: (record: DataType) => (
        <Select
          disabled={createdBy !== loginid}
          defaultValue={record.permission}
          onChange={(value) => handleRoleChange(value, record)}
        >
          <Option value="read">Read</Option>
          <Option value="write">Write</Option>
        </Select>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (record: DataType) => (
        <Button
          type="primary"
          disabled={createdBy !== loginid}
          onClick={() => handleRemoveAccess(record)}
        >
          Remove Access
        </Button>
      ),
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>Shared With</h1>
      </div>
      <Table dataSource={userdata} columns={columns} rowKey="id" />
    </div>
  );
};

export default WizardSharedWith;
