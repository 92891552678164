import { mxCell, mxGraph } from '@anekonnect/mxgraph';

import Constant from '../Constant';
import {
  getTotalHeightOfContainer,
  getLabelPin,
  getCenter,
  getPrevHeight,
  getCurrentLastOffsetY,
} from '../Helper';
import { ComponentData, Possible, Ref } from '../../../Types';
import { PreviousGeo } from '../Types';

import { createCableContainer, createCablePin, createLabelPin } from './Core';

import { mx } from '~/constants/wizard';
import { ConfigProp } from '~/store/reducers/configs';

export const drain = (
  graph: mxGraph,
  componentData: ComponentData,
  parent: mxCell,
  geometryRef: Ref<PreviousGeo[]>,
  indexOfCore: number,
  configs: ConfigProp,
) => {
  const { mxPoint } = mx;

  let cablePinHeight = 0;
  let connectorPinGeoY = 0;

  const endTerminalPointLeft = '-0.5';
  const endTerminalPointRight = '0.5';
  const portHeight = 4;

  const { defaultAlias, position, type, id, index } = componentData;
  const totalHeightOfContainer = getTotalHeightOfContainer(componentData);
  const rootId = `${type}_schematics_${id}_${index}_draw`;

  let resultOfssets: Possible = [];
  let componentContainer: mxCell | null = null;

  const coreData = componentData.cores[indexOfCore];
  const lastIndex = coreData.count - 1;
  const { gauge, units } = getLabelPin(componentData, 'cores', indexOfCore);
  const prevHeight = getPrevHeight(componentData, indexOfCore);
  const isFirstKey = indexOfCore === 0;

  if (isFirstKey) {
    componentContainer = createCableContainer(
      graph,
      componentData,
      `${rootId}_container`,
      defaultAlias,
      parent,
      totalHeightOfContainer,
      {
        x: position.schematics.x,
        y: position.schematics.y,
      },
      configs,
    );
  } else {
    componentContainer =
      geometryRef.current[indexOfCore - 1]?.componentContainer ||
      geometryRef.current[0]?.componentContainer;
  }

  const children = componentContainer?.children || [];
  let lastChildIndex = children.length;

  for (let index = 0; index < coreData.count; index++) {
    const { currentlastOffsetY } = getCurrentLastOffsetY(
      geometryRef,
      indexOfCore,
      index,
      Constant.cablePinHeight.drain,
      prevHeight,
    );

    const currentHeight = Constant.cablePinHeight.drain;
    let lastOffsetY = 0;

    if (isFirstKey) {
      lastOffsetY = currentlastOffsetY;
    } else {
      lastOffsetY = currentlastOffsetY + currentHeight * index;
    }

    const connectorPin = createCablePin(
      graph,
      componentData,
      `${rootId}_core_${coreData.type}_${Constant.cablePinId}_${lastChildIndex}`,
      null,
      componentContainer as mxCell,
      80,
      Constant.cablePinHeight.drain,
      lastOffsetY,
      isFirstKey,
    );
    lastChildIndex++;

    cablePinHeight = connectorPin.geometry.height;
    connectorPinGeoY = connectorPin.geometry.y;

    const labelPin = createLabelPin(
      graph,
      `${rootId}_${Constant.labelPinId}_${index}`,
      `#${gauge} ${units}`,
      // TODO: Make to const
      64,
      10,
      connectorPin,
    );

    if (index === lastIndex) {
      resultOfssets.push({
        lastOffsetY: connectorPin.geometry.offset.y,
        componentContainer: isFirstKey ? componentContainer : null,
        key: `${indexOfCore}_${coreData.type}`,
      });

      geometryRef.current[indexOfCore] = resultOfssets[0];
      resultOfssets = [];
    }

    const portLeft = graph.insertVertex(
      connectorPin,
      `${rootId}_${Constant.portLeftId}_${index}`,
      null,
      0,
      0,
      connectorPin.geometry.width - labelPin.geometry.width + 2,
      4,
      'shape=line;align=right;verticalAlign=middle;routingCenterX=' +
        endTerminalPointLeft +
        ';spacingRight=10;fontColor=#000;strokeColor=#000;rotatable=0;',
    );
    portLeft.geometry.relative = true;
    portLeft.geometry.offset = new mxPoint(
      -(connectorPin.geometry.width - labelPin.geometry.width) / 2 - 2,
      getCenter(connectorPinGeoY, cablePinHeight, portHeight) - 0.2,
    );
    portLeft.setConnectable(true);
    const portRight = graph.insertVertex(
      connectorPin,
      `${rootId}_${Constant.portRightId}_${index}`,
      null,
      0,
      0,
      connectorPin.geometry.width - labelPin.geometry.width + 2,
      4,
      `shape=line;align=left;verticalAlign=middle;routingCenterX=${endTerminalPointRight};spacingLeft=10;fontColor=${Constant.baseColor};strokeColor=${Constant.baseColor};rotatable=0;`,
    );
    portRight.geometry.relative = true;
    portRight.geometry.offset = new mxPoint(
      connectorPin.geometry.width - labelPin.geometry.offset.x,
      getCenter(connectorPinGeoY, cablePinHeight, 4),
    );
    portRight.setConnectable(true);
  }
};
