import { AutoComplete, Badge, Input } from 'antd';
import Axios from 'axios';
import { groupBy, isEmpty } from 'lodash';
import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import styles from './WizardHeaderSearch.module.scss';

import { getSearchPartItems } from '~/api/AuthorizedGets';
import { wizardAddComponent } from '~/store/actions/wizard/Component';
import { AppState } from '~/store/reducers';
import { PartItem } from '~/types';
import { isStateLoading } from '~/utils/state';

type PropsFromState = {
  isLoading: boolean;
};

type PropsFromDispatch = {
  wizardAddComponent: typeof wizardAddComponent;
};

type WizardHeaderSearchProps = PropsFromState & PropsFromDispatch;

type optionItem = {
  value: string;
  label: ReactElement;
};

type option = {
  label: ReactElement;
  options: optionItem[];
};

const renderTitle = (title: string, count: number) => {
  return (
    <div className={styles.titleWrapper}>
      <span className={styles.titleText}>{title}</span>
      <Badge count={count} />
    </div>
  );
};

const renderItem = (title: string, key?: number, addComponent?: () => void) => {
  return {
    key,
    value: title,
    label: (
      <div
        id="wizard-header-search-result"
        className={styles.item}
        onClick={() => addComponent && addComponent()}
      >
        {title}
      </div>
    ),
  };
};

const WizardHeaderSearch = ({ isLoading, wizardAddComponent }: WizardHeaderSearchProps) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [partItems, setPartItems] = useState<PartItem[]>([]);
  const [options, setOptions] = useState<option[]>([]);

  const keysRef = useRef<number[]>([]);

  const doSearch = useCallback(() => {
    if (isEmpty(searchValue)) {
      setPartItems([]);

      return;
    }

    const source = Axios.CancelToken.source();
    const filters = {};
    getSearchPartItems(searchValue, filters, source).then((result: PartItem[]) => {
      if (result !== undefined) {
        setPartItems(PartItem.fromArray(result));
      }
    });

    return () => {
      // Cancel Request when unmounting
      source.cancel();
    };
  }, [searchValue]);

  useEffect(doSearch, [doSearch]);

  const getOptions = useCallback(() => {
    const groups = groupBy(partItems, 'type');
    const keys = Object.keys(groups);

    const addComponent = (item: PartItem, alias?: string) => {
      keysRef.current.push(item.id);

      const length = keysRef.current.filter((v) => v === item.id).length;
      const subId = length > 1 ? length - 1 : 0;

      if (item.molded_cable_whip && item.cable_whip) {
        const filter = { t: 'cable' };
        getSearchPartItems(item.cable_whip, filter).then((result: PartItem[]) => {
          if (result !== undefined && result.length > 0) {
            const cable = result.find((v) => v.name === item.cable_whip);
            cable && addComponent(cable, item.cable_whip_alias);
          }
        });
      }

      wizardAddComponent(item, subId, alias);
    };

    let result: option[] = keys.map((key) => {
      const count = groups[key].length;

      return {
        label: renderTitle(key, count),
        options: groups[key].map((item) => {
          return renderItem(item.name, item.id, () => addComponent(item));
        }),
      };
    });

    if (isEmpty(result) && !isEmpty(searchValue)) {
      result = [
        {
          label: renderTitle('Not Found', 0),
          options: [renderItem(`Component ${searchValue} is not found`)],
        },
      ];
    }

    setOptions(result);
  }, [partItems, searchValue, wizardAddComponent]);

  useEffect(getOptions, [getOptions]);

  return (
    <AutoComplete
      popupClassName="certain-category-search-dropdown"
      options={options}
      style={{ width: 216 }}
    >
      <Input.Search
        loading={isLoading}
        placeholder="Search all components"
        value={searchValue}
        enterButton
        onChange={(e) => setSearchValue(e.target.value)}
        onSearch={setSearchValue}
      />
    </AutoComplete>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    isLoading: isStateLoading(state.action, 'searchPartItems'),
  };
};

const mapDispatchToProps = {
  wizardAddComponent,
};

export default connect(mapStateToProps, mapDispatchToProps)(WizardHeaderSearch);
