import { Divider, Row, Col, Pagination, Grid, Radio, Typography } from 'antd';
import Axios from 'axios';
import React, { Key, useCallback, useEffect, useMemo, useState } from 'react';

import {
  getDataAssemblies,
  getDataOtherAssemblies,
  getDataReviewRequestedAssemblies,
  getDataSharedAssemblies,
  getPublicAssemblies,
} from '~/api/AuthorizedGets';
import { useAppSelector } from '~/store/hooks';
import { AppState } from '~/store/reducers';
import { PartItem } from '~/types';
import AssembliesTable from './AssembliesTable';

import styles from './Assemblies.module.scss';

const { useBreakpoint } = Grid;

const Assemblies = () => {
  const whoamiData = useAppSelector((state: AppState) => state.data.whoami.data);
  const { isTenant } = useAppSelector((state: AppState) => state.data.tenantConfig);
  const roles = useMemo(() => (whoamiData !== undefined ? whoamiData['roles'] : []), [whoamiData]);
  const isAdmin = useMemo(() => roles.includes('SuperAdmin') || roles.includes('Admin'), [roles]);

  const { xs } = useBreakpoint();

  const [category, setCategory] = useState('owned');
  const [dataSource, setDataSource] = useState<PartItem[]>();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageLimit, setPageLimit] = useState(pageSize);
  const [pageOffset, setPageOffset] = useState(0);
  const [loading, setLoading] = useState(false);

  const [queryParams, setQueryParams] = useState({});

  const convertColumnName = (column: string) => {
    switch (column) {
      case 'id':
        return 'assemblyId';
      case 'name':
        return 'assemblyName';
      case 'created_by_name':
        return 'ownerName';
      case 'tags':
        return 'tagName';
      case 'user_drawing_number':
        return 'userDrawingNumber';
      case 'user_drawing_revision':
        return 'userDrawingRevision';
      default:
        return column;
    }
  };

  const convertSortOrder = (field: string, order: string) => {
    /**
     * sortOrder:
        0 => None (default)
        1 => AssemblyId (ASC)
        2 => AssemblyId (DESC)
        3 => AssemblyName (ASC)
        4 => AssemblyName (DESC)
        5 => UpdatedAt (ASC)
        6 => Updateat (DESC)
        7 => UserDrawingNumber (ASC)
        8 => UserDrawingNumber (DESC)
        9 => UserDrawingRevision (ASC)
        10 => UserDrawingRevision (DESC)
     */
    switch (field) {
      case 'id':
        return order === 'ascend' ? 1 : 2;
      case 'name':
        return order === 'ascend' ? 3 : 4;
      case 'updated_at':
        return order === 'ascend' ? 5 : 6;
      case 'user_drawing_number':
        return order === 'ascend' ? 7 : 8;
      case 'user_drawing_revision':
        return order === 'ascend' ? 9 : 10;
      default:
        return 0;
    }
  };

  const handleGetDataAssemblies = useCallback(() => {
    const source = Axios.CancelToken.source();

    setLoading(true);

    if (category === 'owned') {
      getDataAssemblies(pageLimit, pageOffset, queryParams, source).then(
        (result: { data: PartItem[]; count: number }) => {
          setLoading(false);

          if (result !== undefined) {
            setTotalData(result.count);
            setDataSource(PartItem.fromArray(result.data));
          }
        },
      );
    }

    if (category === 'shared') {
      getDataSharedAssemblies(pageLimit, pageOffset, queryParams, source).then(
        (result: { data: PartItem[]; count: number }) => {
          setLoading(false);

          if (result !== undefined) {
            setTotalData(result.count);
            setDataSource(PartItem.fromArray(result.data));
          }
        },
      );
    }

    if (category === 'other') {
      getDataOtherAssemblies(pageLimit, pageOffset, queryParams, source).then(
        (result: { data: PartItem[]; count: number }) => {
          setLoading(false);

          if (result !== undefined) {
            setTotalData(result.count);
            setDataSource(PartItem.fromArray(result.data));
          }
        },
      );
    }

    if (category === 'public') {
      getPublicAssemblies(pageLimit, pageOffset, queryParams, source).then(
        (result: { data: PartItem[]; count: number }) => {
          setLoading(false);

          if (result !== undefined) {
            setTotalData(result.count);
            setDataSource(PartItem.fromArray(result.data));
          }
        },
      );
    }

    if (category === 'review-requested') {
      getDataReviewRequestedAssemblies(pageLimit, pageOffset, queryParams, source).then(
        (result: { data: PartItem[]; count: number }) => {
          setLoading(false);

          if (result !== undefined) {
            setTotalData(result.count);
            setDataSource(PartItem.fromArray(result.data));
          }
        },
      );
    }

    return () => {
      // Cancel Request when unmounting
      source.cancel();
    };
  }, [pageLimit, pageOffset, category, queryParams]);

  useEffect(handleGetDataAssemblies, [handleGetDataAssemblies]);

  const handlePageChange = (value: number) => {
    const max = value * pageSize;
    const offset = max - pageSize;

    setCurrentPage(value);
    setPageOffset(offset);
  };

  const onShowSizeChange = (current: number, newPageSize: number) => {
    const max = current * newPageSize;
    const offset = max - newPageSize;

    setCurrentPage(current);
    setPageSize(newPageSize);
    setPageLimit(newPageSize);
    setPageOffset(offset);
  };

  const handleServerSearch = useCallback((key: Key, column: string) => {
    setQueryParams((query) => ({ ...query, [convertColumnName(column)]: key }));
  }, []);

  const handleServerSort = useCallback((field: string, order: string) => {
    setQueryParams((query) => ({ ...query, sortOrder: convertSortOrder(field, order) }));
  }, []);

  const renderListView = useMemo(
    () => (
      <AssembliesTable
        category={category}
        dataSource={dataSource}
        handleServerSearch={handleServerSearch}
        handleServerSort={handleServerSort}
        isLoading={loading}
      />
    ),
    [category, dataSource, handleServerSearch, handleServerSort, loading],
  );

  return (
    <React.Fragment>
      <Row>
        <Col span={24}>
          <Typography.Title level={2} style={{ marginTop: '0px' }}>
            Assemblies
          </Typography.Title>
          <div className={styles.header}>
            <Radio.Group
              buttonStyle="solid"
              value={category}
              onChange={(e) => {
                setCurrentPage(1);
                setPageSize(10);
                setPageLimit(10);
                setPageOffset(0);
                setCategory(e.target.value);
              }}
            >
              <Radio.Button value="owned">Owned</Radio.Button>
              <Radio.Button value="shared">Shared</Radio.Button>
              {isAdmin && <Radio.Button value="other">Other</Radio.Button>}
              {!isAdmin && isTenant && (
                <Radio.Button value="public">Published Assemblies</Radio.Button>
              )}
              {isTenant && <Radio.Button value="review-requested">Review Requested</Radio.Button>}
            </Radio.Group>
            <span>Found {totalData || 0} Assemblies</span>
          </div>
        </Col>
      </Row>
      <Row className={styles.content}>
        <Col span={24}>
          <Divider style={{ margin: '14px 0' }} />
          {renderListView}
          {dataSource && (
            <Pagination
              className={styles.pagination}
              current={currentPage}
              pageSize={pageSize}
              simple={xs}
              total={Number(totalData)}
              showSizeChanger
              onChange={handlePageChange}
              onShowSizeChange={onShowSizeChange}
            />
          )}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Assemblies;
