import { Reducer } from 'redux';
import { ClickedPostion } from '.';

export const initialPostion: ClickedPostion = {
  x: 100,
  y: 100,
};

export const SET_POSITION = 'SET_POSITION';

export const setPosition = (x: number, y: number) => ({
  type: SET_POSITION,
  payload: { x, y },
});

export const postionReducer: Reducer<ClickedPostion> = (
  state: ClickedPostion = initialPostion,
  action: any,
) => {
  switch (action.type) {
    case SET_POSITION:
      return {
        ...state,
        x: action.payload.x,
        y: action.payload.y,
      };
    default:
      return state;
  }
};
