import { DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Select, Space, Table } from 'antd';
import { Key, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { wizardAddTabulatedData, wizardRemoveTabulatedData } from '~/store/actions/wizard/Action';
import { AppState } from '~/store/reducers';
import EditableCell from './EditableCell';
import EditableRow from './EditableRow';
import { cloneDeep } from 'lodash';

import './style.scss';
import { useAppDispatch } from '~/store/hooks';

type PropsFromState = {
  tabulatedData: any;
};

type PropsFromDispatch = {
  wizardAddTabulatedData: typeof wizardAddTabulatedData;
  wizardRemoveTabulatedData: typeof wizardRemoveTabulatedData;
};

type EditableTableProps = Parameters<typeof Table>[0] & PropsFromState & PropsFromDispatch;

type WizardTabulatedDataProps = EditableTableProps;

const WizardTabulatedData = ({
  tabulatedData,
  wizardRemoveTabulatedData,
  wizardAddTabulatedData,
}: WizardTabulatedDataProps) => {
  const initialDataSource = [
    {
      key: '0',
      extension: 'Variable Name',
      'var-1': 'L1',
    },
    {
      key: '1',
      extension: 'Column Width in Pixels',
      'var-1': '40',
    },
    {
      key: '2',
      extension: 'Column Units',
      'var-1': 'in',
    },
  ];

  const initialColumns = [
    {
      title: 'Assembly Dash Extension',
      dataIndex: 'extension',
      editable: true,
    },
    {
      title: '1',
      dataIndex: 'var-1',
      editable: true,
    },
    {
      title: 'Operation',
      dataIndex: 'operation',
      render: (text: any, record: any) =>
        dataSource.length >= 1 && record.key !== '0' && record.key !== '1' && record.key !== '2' ? (
          <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
            <Button type="primary" ghost>
              Delete
            </Button>
          </Popconfirm>
        ) : null,
    },
  ];

  const [dataSource, setDataSource] = useState(initialDataSource);
  const [columns, setColumns] = useState(initialColumns);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (tabulatedData.data.length > 0) {
      setDataSource(tabulatedData.data);
      setColumns(tabulatedData.columns);
    }
  }, [tabulatedData]);

  const handleDelete = (key: Key) => {
    setDataSource(dataSource.filter((item) => item.key !== key));
    wizardRemoveTabulatedData(key);
    const newTabulatedData = {
      data: dataSource.filter((item) => item.key !== key),
      columns,
    };
    dispatch(wizardAddTabulatedData(newTabulatedData));
  };

  const handleDeleteColumn = (index: string) => {
    const finalColumns = columns.filter((item) => item.dataIndex !== index);
    setColumns(finalColumns);
    const newTabulatedData = {
      data: dataSource,
      columns: finalColumns,
    };
    dispatch(wizardAddTabulatedData(newTabulatedData));
  };

  const handleAdd = () => {
    const newData: any = {};
    const count = dataSource.length;
    columns.forEach((column: any) => {
      if (column.dataIndex !== 'operation') {
        newData.key = `${count}`;
        newData[column.dataIndex] = 'Nil';
      }
    });

    setDataSource([...dataSource, newData]);
    setColumns(columns);
    const newTabulatedData = {
      data: [...dataSource, newData],
      columns,
    };
    dispatch(wizardAddTabulatedData(newTabulatedData));
  };

  const handleAddColumn = () => {
    const indexCol = columns.length - 1;
    const newColumn = {
      title: `${indexCol}`,
      dataIndex: `var-${indexCol}`,
      editable: true,
    };
    const finalColumns: any[] = [...columns];
    const index = finalColumns.length - 1;
    const copiedDataSource = cloneDeep(dataSource);
    finalColumns.splice(index, 0, newColumn);
    const finalDataSources = copiedDataSource.map((element: any) => {
      if (element.key === '2') {
        element[`var-${indexCol}`] = 'in';
      } else if (element.key === '1') {
        element[`var-${indexCol}`] = '40';
      } else if (element.key === '0') {
        element[`var-${indexCol}`] = `L${indexCol}`;
      } else {
        element[`var-${indexCol}`] = 'Nil';
      }

      return { ...element };
    });

    setColumns([...finalColumns]);
    setDataSource([...finalDataSources]);

    const newTabulatedData = {
      data: [...finalDataSources],
      columns: [...finalColumns],
    };
    dispatch(wizardAddTabulatedData(newTabulatedData));
  };

  const handleUnitChange = (keyName: any) => (val: any) => {
    const finalData = cloneDeep(dataSource).map((item: any) => {
      if (item.key === '2') {
        item[keyName] = val;

        return {
          ...item,
        };
      }

      return {
        ...item,
      };
    });

    setDataSource([...finalData]);
    const newTabulatedData = {
      data: [...finalData],
      columns,
    };
    dispatch(wizardAddTabulatedData(newTabulatedData));
  };

  const handleSave = (row: any) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item: any) => row.key === item.key);
    const item = newData[index];

    newData.splice(index, 1, {
      ...item,
      ...row,
    });

    setDataSource([...newData]);

    const newTabulatedData = {
      data: [...newData],
      columns,
    };
    dispatch(wizardAddTabulatedData(newTabulatedData));
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const sources = cloneDeep(dataSource).map((element: any) => {
    if (element.key === '2') {
      const unitElementObject: any = {};
      Object.keys(element).forEach((elmKey) => {
        if (elmKey === 'extension' || elmKey === 'opearation' || elmKey === 'key') {
          unitElementObject[elmKey] = element[elmKey];
        } else {
          unitElementObject[elmKey] = (
            <Select
              style={{ width: 120 }}
              value={element[elmKey]}
              onChange={handleUnitChange(elmKey)}
            >
              <Select.Option value="in">Inches</Select.Option>
              <Select.Option value="mm">mm</Select.Option>
              <Select.Option value="cm">cm</Select.Option>
              <Select.Option value="m">meters</Select.Option>
              <Select.Option value="ft">Feet</Select.Option>
              <Select.Option value="n/a">N/A</Select.Option>
            </Select>
          );
        }
      });

      return { ...unitElementObject };
    }

    return { ...element };
  });

  const newColumns = columns.map((col: any) => {
    if (col.title === 'Operation' && !col.render) {
      col.render = (_: any, record: any) =>
        dataSource.length >= 1 && record.key !== '0' && record.key !== '1' && record.key !== '2' ? (
          <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
            <Button type="primary" ghost>
              Delete
            </Button>
          </Popconfirm>
        ) : null;
    }

    if (!col.editable) {
      return col;
    }

    const convertedTitle =
      col.title === 'Assembly Dash Extension' || col.title === 'Operation' ? (
        col.title
      ) : (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>{`Var ${col.title}`}</span>
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDeleteColumn(`var-${col.title}`)}
          >
            <Button type="primary" shape="circle">
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </div>
      );

    return {
      ...col,
      title: convertedTitle,
      onCell: (record: any) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: convertedTitle,
        handleSave: handleSave,
      }),
    };
  });

  return (
    <div>
      <Space style={{ marginBottom: 10 }}>
        <Button type="primary" onClick={handleAdd}>
          Add Row
        </Button>
        <Button type="primary" onClick={handleAddColumn}>
          Add Variable
        </Button>
      </Space>
      <Table
        columns={newColumns}
        components={components}
        dataSource={sources}
        pagination={false}
        rowClassName={() => 'editable-row'}
        scroll={{ x: 'max-content' }}
        bordered
      />
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    tabulatedData: state.assemblyWizard.tabulatedData,
  };
};

const mapDispatchToProps = {
  wizardAddTabulatedData,
  wizardRemoveTabulatedData,
};

export default connect(mapStateToProps, mapDispatchToProps)(WizardTabulatedData);
