import { FolderOpenOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Dropdown, MenuProps, message, Modal, Space } from 'antd';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { mxGraph } from '@anekonnect/mxgraph';
import { FormInstance } from 'antd/lib/form';

import { AssemblyForm } from '../Types';
import WizardHeaderSaveAssembly from '../WizardHeaderSaveAssembly';

import { deleteAssembly } from '~/api/AuthorizedDeletes';
import { postAsBuiltAssembly, postCopyAssembly } from '~/api/AuthorizedPosts';
import { defaultErrorHandler } from '~/api/HttpError';
import { CopyModal } from '~/components/Modals';
import { LINK_ASSEMBLY_NEW } from '~/constants/paths';
import { AppState } from '~/store/reducers';
import {
  wizardResetDrawingData,
  wizardUpdateAssemblyVisibility,
} from '~/store/actions/wizard/Action';
import { getPaymentStatus, paymentStatusIsPaid } from '~/utils/helper';
import MenuButton from './MenuButton';
import { useAppSelector } from '~/store/hooks';
import { AsBuiltModal } from '~/components/Modals/AsBuiltModal';
import { VisibilityModal } from '~/components/Modals/VisibilityModal';
import { putChangeVisibility } from '~/api/AuthorizedPuts';
import { snackBarOpen } from '~/store/actions/ui/SnackBar';
import { useDispatch } from 'react-redux';

type PropsFromState = {
  assemblyId?: number;
  whoamiData: {
    payment_status?: string;
    type?: string;
  };
};

type PropsFromDispatch = {
  wizardResetDrawingData: typeof wizardResetDrawingData;
};

type MenuFileProps = {
  graph: mxGraph;
  assemblyForm: FormInstance<AssemblyForm>;
} & PropsFromState &
  PropsFromDispatch;

const MenuFile = ({
  graph,
  assemblyForm,
  assemblyId,
  whoamiData,
  wizardResetDrawingData,
}: MenuFileProps) => {
  const navigate = useNavigate();

  const assemblyStatus = useAppSelector((state) => state.assemblyWizard.status);
  const isTenant = useAppSelector((state) => state.data.tenantConfig.isTenant);
  const isAssembleyPublic = useAppSelector((state) => state.assemblyWizard.isPublic);
  const [isAssemblyVisibile, setIsAssemblyVisibile] = useState(isAssembleyPublic);
  const [isOpenCopyModal, setIsOpenCopyModal] = useState(false);
  const [isOpenAsBuiltModal, setIsOpenAsBuiltModal] = useState(false);
  const [isOpenVisibilityModal, setIsOpenVisibilityModal] = useState(false);
  const paymentStatus = getPaymentStatus(whoamiData);
  const isPaid = paymentStatusIsPaid(paymentStatus);

  useEffect(() => {
    setIsAssemblyVisibile(isAssembleyPublic);
  }, [isAssembleyPublic]);

  const dispatch = useDispatch();

  const copyHandler = async (values: { name: string; revision: boolean }) => {
    setIsOpenCopyModal(false);
    const { id } = await postCopyAssembly(values, defaultErrorHandler, String(assemblyId));
    message.success(`Assembly Copied Successfully`, 1).then(() => {
      navigate(`/assemblies/wizard/edit/${id}`);
      window.location.reload();
    });
  };

  const asBuiltHandler = async (values: { name: string; revision: boolean }) => {
    setIsOpenAsBuiltModal(false);
    const { id } = await postAsBuiltAssembly(values, defaultErrorHandler, String(assemblyId));
    message.success(`Set assembly as built is successfull`, 1).then(() => {
      navigate(`/assemblies/wizard/edit/${id}`);
      window.location.reload();
    });
  };

  const changeVisibilityHandler = async () => {
    setIsOpenVisibilityModal(false);
    const response = await putChangeVisibility(`${assemblyId}/change/visibility`);
    if (response.id) {
      dispatch(
        snackBarOpen(`Assembly set to ${response.is_public ? 'Public' : 'Private'} Visibility`),
      );
      setIsAssemblyVisibile(response.is_public);
      dispatch(wizardUpdateAssemblyVisibility(response.is_public));
    }
  };

  const handleDelete = async () => {
    Modal.confirm({
      title: 'Are you sure to delete this assembly?',
      content: 'Once deleted, the Assembly cannot be retrieved.',
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        const result = await deleteAssembly(assemblyId);

        if (result) {
          message.success(`Assembly Deleted Successfully`);
          window.open(LINK_ASSEMBLY_NEW, '_self');
        }
      },
      okText: 'Yes',
      cancelText: 'No',
    });
  };

  const handleReset = () => {
    graph.getModel().clear();

    wizardResetDrawingData();

    message.success(`Assembly Reset Successfully!`);
  };

  const items: MenuProps['items'] = [
    {
      key: 'new',
      label: (
        <Link to={LINK_ASSEMBLY_NEW} reloadDocument>
          New
        </Link>
      ),
    },
    assemblyStatus.toLowerCase() === 'draft' || assemblyStatus.toLowerCase() === 'rejected'
      ? {
          key: 'save',
          label: (
            <WizardHeaderSaveAssembly assemblyForm={assemblyForm} buttonType="file" graph={graph} />
          ),
        }
      : null,
    {
      key: 'as-built',
      label: 'As Built',
      disabled: assemblyStatus.toLocaleLowerCase() !== 'published',
      onClick: () => setIsOpenAsBuiltModal(true),
    },
    isTenant
      ? {
          key: 'visibility',
          label: isAssemblyVisibile ? 'Make Private' : 'Make Public',
          disabled:
            assemblyStatus.toLocaleLowerCase() !== 'published' &&
            assemblyStatus.toLocaleLowerCase() !== 'as-built',
          onClick: () => setIsOpenVisibilityModal(true),
        }
      : null,
    {
      key: 'copy',
      label: 'Copy',
      disabled: !assemblyId || !isPaid,
      onClick: () => setIsOpenCopyModal(true),
    },
    {
      key: 'delete',
      label: 'Delete',
      disabled: !assemblyId || !isPaid,
      onClick: handleDelete,
    },
    {
      key: 'reset',
      label: 'Reset',
      onClick: handleReset,
    },
  ];

  return (
    <>
      <CopyModal
        isOpen={isOpenCopyModal}
        onCancel={() => setIsOpenCopyModal(false)}
        onConfirm={copyHandler}
      />
      <AsBuiltModal
        isOpen={isOpenAsBuiltModal}
        onCancel={() => setIsOpenAsBuiltModal(false)}
        onConfirm={asBuiltHandler}
      />
      <VisibilityModal
        isOpen={isOpenVisibilityModal}
        onCancel={() => setIsOpenVisibilityModal(false)}
        onConfirm={() => changeVisibilityHandler()}
      />
      <Dropdown menu={{ items }} trigger={['click']}>
        <Space>
          <MenuButton icon={<FolderOpenOutlined />} label="File" />
        </Space>
      </Dropdown>
    </>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    assemblyId: state.assemblyWizard.id,
    whoamiData: state.data.whoami.data,
  };
};

const mapDispatchToProps = {
  wizardResetDrawingData,
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuFile);
