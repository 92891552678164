import { useCallback, useEffect } from 'react';

import Layout from './containers/Layout';
import SnackBar from './components/SnackBar';
import { AppState } from './store/reducers';
import { getTenantConfig, getWhoami } from './api/AuthorizedGets';
import { setSupportsWebgl } from './store/actions/ui/BrowserSupport';
import {
  wizardSetApprovalHistoriesTablePosition,
  wizardSetBillOfMaterialsPosition,
  wizardSetCustomerApprovalsPosition,
  wizardSetPaperSize,
  wizardSetRevisionsPosition,
  wizardSetTitleBlockData,
  wizardUpdatePaperSizeData,
} from './store/actions/wizard/Action';
import Loading from './components/Loading';
import { useAppDispatch, useAppSelector } from './store/hooks';
import { ConfigProvider } from 'antd';

type AppProps = {
  children: React.ReactNode;
};

const App = ({ children }: AppProps) => {
  const dispatch = useAppDispatch();
  const data = useAppSelector((state: AppState) => state.data);
  const { tenantConfig, whoami, auth } = data;
  const tenantConfigData = tenantConfig.data;
  const userSchema = tenantConfigData?.user_schema;
  const whoamiData = whoami.data;
  const isAuthenticated = auth.authenticated;

  useEffect(() => {
    const getTenantConfigData = async () => {
      await getTenantConfig();
    };

    getTenantConfigData();
  }, []);

  useEffect(() => {
    const getWhoamiData = async () => {
      await getWhoami();
    };

    if (isAuthenticated) {
      getWhoamiData();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const primaryColor = tenantConfigData?.ui_config?.primary_color || '#00D3B2';

    document.documentElement.style.setProperty('--primary-color', primaryColor);
  }, [tenantConfigData?.ui_config?.primary_color]);

  useEffect(() => {
    if (userSchema) {
      const userSchemaJson = JSON.parse(userSchema);
      if (userSchemaJson?.properties?.default_paper_size?.default) {
        dispatch(
          wizardSetPaperSize(userSchemaJson?.properties?.default_paper_size?.default.toLowerCase()),
        );
      }
    }

    if (tenantConfigData?.title_block_data_schema) {
      dispatch(
        wizardSetTitleBlockData(JSON.parse(tenantConfigData?.title_block_data_schema).display),
      );
    }

    if (tenantConfigData?.bill_of_materials_table_config_display) {
      const bomTableConfig = JSON.parse(tenantConfigData?.bill_of_materials_table_config_display);
      dispatch(wizardSetBillOfMaterialsPosition(bomTableConfig.table.position));
    }

    if (tenantConfigData?.revisions_table_config_display) {
      const revisionTableConfig = JSON.parse(tenantConfigData?.revisions_table_config_display);
      dispatch(wizardSetRevisionsPosition(revisionTableConfig.table.position));
    }
    if (tenantConfigData?.customer_approvals_config_display) {
      const customerApprovalsTableConfig = JSON.parse(
        tenantConfigData?.customer_approvals_config_display,
      );
      dispatch(wizardSetCustomerApprovalsPosition(customerApprovalsTableConfig.table.position));
    }

    if (tenantConfigData?.approval_history_table_config_display) {
      const approvalHistoryTableConfig = JSON.parse(
        tenantConfigData?.approval_history_table_config_display,
      );
      dispatch(wizardSetApprovalHistoriesTablePosition(approvalHistoryTableConfig.table.position));
    }

    if (whoamiData?.template_settings) {
      dispatch(wizardUpdatePaperSizeData(JSON.parse(whoamiData?.template_settings)));
    } else {
      if (tenantConfigData?.template_config) {
        dispatch(wizardUpdatePaperSizeData(JSON.parse(tenantConfigData?.template_config)));
      }
    }

    if (whoamiData?.title_block_schema) {
      dispatch(wizardSetTitleBlockData(JSON.parse(whoamiData?.title_block_schema)));
    }

    if (whoamiData?.default_paper_size) {
      dispatch(wizardSetPaperSize(whoamiData?.default_paper_size.toLowerCase()));
    }
  }, [dispatch, tenantConfigData, userSchema, whoamiData]);

  const webglSupport = useCallback(() => {
    try {
      const canvas = document.createElement('canvas');
      const glContext =
        !!window.WebGLRenderingContext &&
        (canvas.getContext('webgl') || canvas.getContext('experimental-webgl'));
      const boolSupportWebgl = !!glContext;
      dispatch(setSupportsWebgl(boolSupportWebgl));
    } catch (e) {
      dispatch(setSupportsWebgl(false));
    }
  }, [dispatch]);

  useEffect(webglSupport, [webglSupport]);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: tenantConfigData?.ui_config?.primary_color || '#00D3B2',
          borderRadius: 2,
        },
      }}
    >
      <Loading isLoading={!tenantConfigData}>
        <Layout>
          {children}
          <SnackBar />
        </Layout>
      </Loading>
    </ConfigProvider>
  );
};

export default App;
