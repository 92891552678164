import React, { useEffect, useState } from 'react';
import { Button, Modal, Select, Input, message, Row, Col, SelectProps } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { getReviewers } from '~/api/AuthorizedGets';
import { postShareAssembly } from '~/api/AuthorizedPosts';
import { defaultErrorHandler } from '~/api/HttpError';

const { Option } = Select;

type ShareProps = {
  isOpen: boolean;
  onCancel: () => void;
  isAuthenticated: boolean;
  isTenant: boolean;
  assemblyId: any;
};

interface ShareData {
  id: string;
  email: string;
  role: string;
  date: string;
}

// Email validation regex
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const RevisedShareModal = ({
  isOpen,
  onCancel,
  isAuthenticated,
  isTenant,
  assemblyId,
}: ShareProps) => {
  const [rows, setRows] = useState<ShareData[]>([{ id: '', email: '', role: 'read', date: '' }]);
  const [userlist, setuserlist] = useState<SelectProps['options']>([]);
  const [emailErrors, setEmailErrors] = useState<boolean[]>([false]); // Track invalid email errors

  useEffect(() => {
    const getReviewersData = async () => {
      const response = await getReviewers();
      setuserlist(
        response.map((user: any) => ({
          key: user.id,
          label: `${user.email}`,
          value: user.id,
        })),
      );
    };

    if (isOpen && isTenant) {
      getReviewersData();
    }
  }, [isOpen, isTenant]);

  const handleInputChange = (index: number, key: keyof ShareData, value: any) => {
    const updatedRows = [...rows];
    updatedRows[index][key] = value;

    if (key === 'email' && !isTenant) {
      const updatedErrors = [...emailErrors];
      updatedErrors[index] = !emailRegex.test(value);
      setEmailErrors(updatedErrors);
    }

    setRows(updatedRows);
  };
  const addRow = () => {
    setRows([...rows, { id: '', email: '', role: 'read', date: '' }]);
    setEmailErrors([...emailErrors, false]); // Add error state for the new row
  };

  const removeRow = (index: number) => {
    const updatedRows = rows.filter((_, i) => i !== index);
    const updatedErrors = emailErrors.filter((_, i) => i !== index);
    setRows(updatedRows);
    setEmailErrors(updatedErrors); // Update email error state
  };

  const handleOK = async () => {
    const payload: {
      can_read: (string | number)[];
      can_write: (string | number)[];
      can_read_for_emails: string[];
      can_write_for_emails: string[];
    } = {
      can_read: [],
      can_write: [],
      can_read_for_emails: [],
      can_write_for_emails: [],
    };

    rows.forEach((row) => {
      if (row.role === 'write') {
        if (isTenant) {
          payload.can_write.push(row.id);
        } else {
          payload.can_write_for_emails.push(row.email);
        }
      } else if (row.role === 'read') {
        if (isTenant) {
          payload.can_read.push(row.id);
        } else {
          payload.can_read_for_emails.push(row.email);
        }
      }
    });
    // console.log('Payloadloop', payload);
    onCancel();

    await postShareAssembly(payload, defaultErrorHandler, String(assemblyId)).then(() => {
      message.success('Assembly Shared Successfully');
    });
    onCancel();
    setRows([{ id: '', email: '', role: 'read', date: '' }]);
  };

  // Check if any email or id is empty or invalid
  const isButtonDisabled = isTenant
    ? rows.some((row) => row.id === '')
    : rows.some((row) => row.email === '') || emailErrors.some((error) => error);

  return (
    <Modal
      width={500}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button disabled={isButtonDisabled} key="ok" type="primary" onClick={() => handleOK()}>
          {isAuthenticated ? 'Share' : 'Ok'}
        </Button>,
      ]}
      title="Share Assembly with"
      open={isOpen}
      onCancel={onCancel}
    >
      <Row gutter={[10, 10]} style={{ marginBottom: '1rem' }}>
        <Col span={16}>
          <strong>Email</strong>
        </Col>
        <Col span={6}>
          <strong>Access</strong>
        </Col>
        <Col span={2}></Col>
      </Row>
      {rows.map((row, index) => (
        <Row gutter={[10, 10]} key={index} style={{ marginBottom: '1rem' }}>
          <Col span={16}>
            {isTenant ? (
              <Select
                showSearch
                value={row.id}
                onChange={(value) => handleInputChange(index, 'id', value)}
                style={{ width: '100%' }}
                optionLabelProp="label"
                options={userlist}
                filterOption={(input, option) =>
                  typeof option?.label === 'string' &&
                  option.label.toLowerCase().includes(input.toLowerCase())
                }
              />
            ) : (
              <Input
                value={row.email}
                type="email"
                onChange={(e) => handleInputChange(index, 'email', e.target.value)}
                placeholder="Enter email"
                status={emailErrors[index] ? 'error' : ''}
              />
            )}
          </Col>
          <Col span={6}>
            <Select
              value={row.role}
              onChange={(value) => handleInputChange(index, 'role', value)}
              style={{ width: '100%' }}
            >
              <Option value="write">Write</Option>
              <Option value="read">Read</Option>
            </Select>
          </Col>
          <Col span={2}>
            {index === 0 ? (
              ''
            ) : (
              <Button
                icon={<DeleteOutlined />}
                onClick={() => removeRow(index)}
                disabled={index === 0}
              />
            )}
          </Col>
        </Row>
      ))}
      <Row justify="center" style={{ marginTop: '1rem' }}>
        <Button type="primary" icon={<PlusOutlined />} onClick={addRow}>
          Add Email
        </Button>
      </Row>
    </Modal>
  );
};
