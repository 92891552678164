import { Button, Col, Descriptions, Row, Space, Tag } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import dayjs from 'dayjs';

import EditTrialDate from './EditTrialDate';

import { deleteDisableUser } from '~/api/AuthorizedDeletes';
import { getUser } from '~/api/AuthorizedGets';
import { putDisableUser } from '~/api/AuthorizedPuts';
import { AppState } from '~/store/reducers';
import { AuthState } from '~/store/reducers/data/Auth';
import { useParams } from 'react-router-dom';
import EditRoles from './EditRoles';
import EditPaymentStatus from './EditPaymentStatus';

type PropsFromState = {
  userData: any;
  auth: AuthState;
  permissions: string[] | undefined;
  roles: string[] | undefined;
  isTenant?: boolean;
};

type UserDetailProps = PropsFromState;

const UserDetail = ({ auth, userData, permissions, roles, isTenant }: UserDetailProps) => {
  const params = useParams();
  const name = params.name;

  const [isDisable, setIsDisable] = useState(false);
  const [isEditRolesOpen, setIsEditRolesOpen] = useState(false);
  const [isEditPaymentStatus, setIsEditPaymentStatus] = useState(false);
  const [isEditTrialDateOpen, setIsEditTrialDateOpen] = useState(false);
  const [trialDate, setTrialDate] = useState<dayjs.Dayjs>(dayjs(userData?.trial_expiry));

  useEffect(() => {
    setTrialDate(dayjs(userData?.trial_expiry));
  }, [userData]);

  const fetchUserData = useCallback(() => {
    if (auth.authenticated) {
      getUser(name).then((user: { disabled: boolean; type: string }) => {
        if (user.disabled) {
          setIsDisable(true);
        }
      });
    }
  }, [auth.authenticated, name]);

  useEffect(fetchUserData, [fetchUserData]);

  const handleDisableUser = () => {
    if (isDisable) {
      deleteDisableUser(userData.id).then((response: { status: string }) => {
        if (response.status === 'done') {
          setIsDisable(false);
        }
      });
    } else {
      putDisableUser(userData.id).then((response: { status: string }) => {
        if (response.status === 'done') {
          setIsDisable(true);
        }
      });
    }
  };

  // console.log('userData?.payment_status', userData?.payment_status);

  return (
    <>
      <EditRoles
        id={userData?.id}
        isOpen={isEditRolesOpen}
        onCancel={() => setIsEditRolesOpen(false)}
        onOk={() => {
          setIsEditRolesOpen(false);
          fetchUserData();
        }}
        currentRoles={userData?.roles}
      />
      <EditPaymentStatus
        id={userData?.id}
        value={userData?.payment_status}
        isOpen={isEditPaymentStatus}
        onCancel={() => setIsEditPaymentStatus(false)}
        onOk={() => {
          setIsEditPaymentStatus(false);
          fetchUserData();
        }}
      />
      <EditTrialDate
        handleDateChange={(date: dayjs.Dayjs) => {
          setTrialDate(date);
        }}
        id={userData?.id}
        isOpen={isEditTrialDateOpen}
        value={trialDate}
        onCancel={() => setIsEditTrialDateOpen(false)}
        onOk={() => {
          setIsEditTrialDateOpen(false);
          fetchUserData();
        }}
      />
      <Row justify="center">
        <Col lg={16} md={24} sm={24} xs={24}>
          {userData && (
            <Descriptions
              extra={
                <Space>
                  {permissions?.includes('RoleAssignment') && (
                    <Button key="edit-roles" onClick={() => setIsEditRolesOpen(true)}>
                      Edit Roles
                    </Button>
                  )}
                  {isTenant === false && roles?.includes('SuperAdmin') && (
                    <Button key="edit-payment-status" onClick={() => setIsEditPaymentStatus(true)}>
                      Edit Payment Status
                    </Button>
                  )}
                  {isTenant === false && (
                    <Button key="edit-trial-date" onClick={() => setIsEditTrialDateOpen(true)}>
                      Edit Trial Date
                    </Button>
                  )}
                  {permissions?.includes('RemoveUser') && (
                    <Button
                      key="disable-user"
                      danger={!isDisable}
                      type="primary"
                      onClick={() => handleDisableUser()}
                    >
                      {isDisable ? 'Enable User' : 'Disable User'}
                    </Button>
                  )}
                </Space>
              }
              title="User Detail"
              bordered
            >
              <Descriptions.Item label="Name" span={3}>
                {userData.name}
              </Descriptions.Item>
              <Descriptions.Item label="Username" span={3}>
                {userData.username}
              </Descriptions.Item>
              <Descriptions.Item label="Email" span={3}>
                {userData.email}
              </Descriptions.Item>
              <Descriptions.Item label="Roles" span={3}>
                {userData.roles?.map((role: string) => (
                  <Tag key={role}>{role}</Tag>
                ))}
              </Descriptions.Item>

              {isTenant === false && (
                <>
                  <Descriptions.Item label="Payment Status" span={3}>
                    {userData.payment_status}
                  </Descriptions.Item>
                  <Descriptions.Item label="Trial Expiration Date" span={3}>
                    {dayjs(userData.trial_expiry).format('MMMM Do YYYY, h:mm:ss a')}
                  </Descriptions.Item>
                </>
              )}
              <Descriptions.Item label="Send me occasional email updates">
                {userData.occassional_email ? 'Yes' : 'No'}
              </Descriptions.Item>
              <Descriptions.Item label="Send me an email when I delete an assembly">
                {userData.delete_assembly_email ? 'Yes' : 'No'}
              </Descriptions.Item>
            </Descriptions>
          )}
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    auth: state.data.auth,
    userData: state.data.user.data,
    permissions: state.data.whoami.data?.permissions,
    roles: state.data.whoami.data?.roles,
    isTenant: state.data.tenantConfig.isTenant,
  };
};

export default connect(mapStateToProps)(UserDetail);
