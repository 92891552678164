import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import { Carousel, Select } from 'antd';
import Axios from 'axios';
import { isEmpty } from 'lodash';
import { ParsedQuery } from 'query-string';
import { RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { ActionCreator } from 'redux';

import WizardHeaderParametricSearch from './WizardHeaderParametricSearch';
import WizardHeaderSearch from './WizardHeaderSearch';

import { KeyValue, PartItem } from '~/types';
import { getParts, selectNavigation, selectPartByPartName } from '~/utils/parts';
import { AppState } from '~/store/reducers';
import { WizardAddComponent, wizardAddComponent } from '~/store/actions/wizard/Component';
import { getPartFilters, getPartItems, getSearchPartItems } from '~/api/AuthorizedGets';
import { Filter, NavigationNode, Part } from '~/api/API';
import { isStateLoading } from '~/utils/state';
import { convertDeepObjectKeysToString } from '~/utils/filter';

import './WizardHeaderAddComponent.scss';
import {
  convertToNestedFormat,
  filterData,
  getFiltersDetails,
  isMultipleFilterExist,
} from '~/utils/double-filter';

type PropsFromState = {
  state: AppState;
  parts: Part[];
  isLoading: boolean;
};

type PropsFromDispatch = {
  wizardAddComponent: ActionCreator<WizardAddComponent>;
};

type WizardHeaderAddComponentProps = { innerRef: RefObject<HTMLDivElement> } & PropsFromState &
  PropsFromDispatch;

const getSelectedNavigation = (
  navigation: NavigationNode | null,
  parsedSearch: ParsedQuery,
): NavigationNode[] | null => {
  if (!navigation || !navigation.nodes) {
    return null;
  }

  if (!parsedSearch || !Object.keys(parsedSearch).length) {
    return navigation.nodes;
  }

  const selected = navigation.nodes.find(
    (e) => parsedSearch[e.name] !== null && parsedSearch[e.name] !== undefined,
  );

  if (selected) {
    return getSelectedNavigation(selected, { ...parsedSearch, [selected.name]: '' });
  }

  return navigation.nodes;
};

const WizardHeaderAddComponent = ({
  innerRef,
  state,
  parts,
  isLoading,
  wizardAddComponent,
}: WizardHeaderAddComponentProps) => {
  const [navigation, setNavigation] = useState<NavigationNode[]>([]);
  const [partForSearch, setPartForSearch] = useState('connector');
  const [partItems, setPartItems] = useState<PartItem[]>([]);
  const [showParametricSearch, setShowParametricSearch] = useState(false);

  // START -> Navigation Logic
  const [rootNavigation, setRootNavigation] = useState(selectNavigation(navigation, partForSearch));
  const [selectedNavigations, setSelectedNavigations] = useState<NavigationNode[] | null>([]);
  const [navigationHistories, setNavigationHistories] = useState<KeyValue[]>([]);
  const [navigationStep, setNavigationStep] = useState(0);
  const [parsedSearch, setParsedSearch] = useState<KeyValue>({});
  const [partFiltersData, setPartFiltersData] = useState<KeyValue>({});
  const [filtersFields, setFiltersFields] = useState<Filter[]>([]);
  const [label404, setLabel404] = useState('');
  const [isShowAll, setIsShowAll] = useState(false);

  const [totalItems, setTotalItems] = useState(0);
  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [pageLimit, setPageLimit] = useState(pageSize);
  const [pageOffset, setPageOffset] = useState(0);

  const keysRef = useRef<number[]>([]);

  useEffect(() => {
    if (state?.data?.tenantConfig?.data?.navigation) {
      setNavigation(state.data.tenantConfig.data.navigation);
    }
  }, [state?.data?.tenantConfig?.data?.navigation]);

  const part = useMemo(() => {
    if (!parts) return null;
    return selectPartByPartName(parts, partForSearch || '');
  }, [parts, partForSearch]);

  const resetPage = () => {
    setTotalItems(0);
    setTotalPage(1);
    setCurrentPage(1);
    setPageOffset(0);
  };

  const onPageChange = (current: number, newPageSize: number) => {
    const max = current * newPageSize;
    const offset = max - newPageSize;

    setCurrentPage(current);
    setPageSize(newPageSize);
    setPageLimit(newPageSize);
    setPageOffset(offset);
  };

  const updateFiltersFields = useCallback(() => {
    const updateFiltersFields = [];

    if (part && part.filters && part.filters.select) {
      updateFiltersFields.push(...part.filters.select);
    }

    if (part && part.filters && part.filters.numeric) {
      updateFiltersFields.push(...part.filters.numeric);
    }
    setFiltersFields(updateFiltersFields);
  }, [part]);

  const defaultState = useCallback(() => {
    setRootNavigation(selectNavigation(navigation, partForSearch));
    updateFiltersFields();
    setParsedSearch({}); // Reset navigation
    setPartItems([]); // Reset part items
    resetPage();
    setIsShowAll(false);
  }, [navigation, partForSearch, updateFiltersFields]);

  useEffect(defaultState, [defaultState]);

  const showAllPartItems = (action = true) => {
    setParsedSearch({});
    setIsShowAll(action);
    resetPage();
  };

  const handleTotalPage = useCallback(
    (totalPage: number) => {
      if (isShowAll || isMultipleFilterExist(parsedSearch)) {
        setTotalPage(totalPage);
      }

      selectedNavigations &&
        selectedNavigations.map((navigation: NavigationNode) => {
          const key = navigation.name;

          if (!isEmpty(parsedSearch) && partFiltersData && !partFiltersData[key]) {
            setTotalPage(totalPage);

            return null;
          }

          return null;
        });

      if (!isEmpty(parsedSearch) && selectedNavigations == null) {
        setTotalPage(totalPage);

        return;
      }
    },
    [isShowAll, parsedSearch, partFiltersData, selectedNavigations],
  );

  const handleGetPartItems = useCallback(
    () => {
      if (!part) {
        return;
      }

      const source = Axios.CancelToken.source();

      const isPagination = true;

      getPartItems(partForSearch, parsedSearch, isPagination, pageLimit, pageOffset, source).then(
        (result: { count: number; data: PartItem[] }) => {
          if (result === undefined) {
            return;
          }

          let totalPage = Math.ceil(result.count / pageLimit);

          if (isMultipleFilterExist(parsedSearch) && result.count > 0) {
            if (result.count !== pageSize) {
              return onPageChange(1, result.count);
            }

            const nestedFilters = convertToNestedFormat(parsedSearch, ['t']);
            const filtersDetails = getFiltersDetails(part, nestedFilters);
            const filteredData = filterData(result.data, filtersDetails);

            if (filteredData.length === 0) {
              setLabel404('No Data.');
            }

            totalPage = 1;
            setTotalItems(filteredData.length);
            setPartItems(PartItem.fromArray(filteredData));
          } else {
            if (result.count === 0) {
              totalPage = 1;
              setLabel404('No Data.');
            }

            setTotalItems(result.count);
            setPartItems(PartItem.fromArray(result.data));
          }

          if (selectedNavigations === null || isShowAll || isMultipleFilterExist(parsedSearch)) {
            handleTotalPage(totalPage);
          }
        },
      );

      return () => {
        // Cancel Request when unmounting
        source.cancel();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isShowAll, pageLimit, pageOffset, parsedSearch, partForSearch, selectedNavigations],
  );

  useEffect(handleGetPartItems, [handleGetPartItems]);

  const handleGetPartFilters = useCallback(() => {
    const source = Axios.CancelToken.source();

    parsedSearch.t = partForSearch;
    getPartFilters(parsedSearch, source).then((filters: KeyValue) => {
      setPartFiltersData(filters);
    });

    return () => {
      // Cancel Request when unmounting
      source.cancel();
    };
  }, [parsedSearch, partForSearch]);

  useEffect(handleGetPartFilters, [handleGetPartFilters]);

  useEffect(() => {
    setSelectedNavigations(getSelectedNavigation(rootNavigation, parsedSearch));
  }, [rootNavigation, parsedSearch]);

  const addPartItem = (item: PartItem, alias?: string) => {
    // console.log(item, 'part item');

    // const type = item.type;
    keysRef.current.push(item.id);

    // console.log('part blank');

    const length = keysRef.current.filter((v) => v === item.id).length;
    const subId = length > 1 ? length - 1 : 0;

    if (item.molded_cable_whip && item.cable_whip) {
      const filter = { t: 'cable' };
      getSearchPartItems(item.cable_whip, filter).then((result: PartItem[]) => {
        if (result !== undefined && result.length > 0) {
          const cable = result.find((v) => v.name === item.cable_whip);
          cable && addPartItem(cable, item.cable_whip_alias);
        }
      });
    }
    // console.log(item, subId, alias, 'add component props');

    wizardAddComponent(item, subId, alias);
  };

  const renderPartItem = (item: PartItem) => {
    // console.log(item, 'part item');

    return (
      <p key={item.id} onClick={() => addPartItem(item)}>
        {item.name}
      </p>
    );
  };

  const handleFiltering = (filter: KeyValue, preserveOldFilters = false) => {
    const filters = !preserveOldFilters ? filter : { ...parsedSearch, ...filter };
    setParsedSearch(convertDeepObjectKeysToString(filters));

    setNavigationHistories([...navigationHistories, filters]);
    setNavigationStep(navigationHistories.length);
    resetPage();
  };

  const getShowAllButton = () => {
    const displayName = part && part.display_name;

    if (isShowAll) {
      return 'Back to Navigation Search';
    }

    return 'Show All ' + displayName;
  };

  const getFilterName = () => {
    if (isShowAll || isMultipleFilterExist(parsedSearch)) {
      return part && part.display_name;
    }

    let filterName: string | null | (string | null)[] =
      selectedNavigations &&
      selectedNavigations.map((e) => {
        const key = e.name;

        if (partFiltersData && !partFiltersData[key]) {
          return part && part.display_name;
        }

        const filter = filtersFields.find((filter) => filter.key === key);

        return filter ? filter.display_name : 'Manufacturer';
      });

    if (selectedNavigations == null) {
      filterName = part ? part.display_name : null;
    }

    return filterName ? filterName : 'Manufacturer';
  };

  const navigateBack = () => {
    navigationHistories.pop();

    if (navigationHistories.length === 0) {
      setParsedSearch({});

      return;
    }

    const step = navigationStep - 1;
    setNavigationStep(step);
    setParsedSearch(navigationHistories[step]);
  };

  const resetFilters = () => {
    setParsedSearch({});
    resetPage();
  };

  // Start Carousel Section
  const carouselProps = {
    dots: false,
    infinite: false,
    arrows: true,
    prevArrow: <CaretLeftOutlined />,
    nextArrow: <CaretRightOutlined />,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (i: number) => {
      const page = i + 1;
      onPageChange(page, pageSize);
    },
  };

  const renderNavigation = (idx: number) => {
    let contentWrapper = <div className="content-wrapper">No Data.</div>;

    if (!isShowAll || !isMultipleFilterExist(parsedSearch)) {
      contentWrapper = (
        <div className="content-wrapper">
          {isLoading && <span>Please wait...</span>}
          {!isLoading &&
            selectedNavigations &&
            selectedNavigations.map((navigation: NavigationNode) => {
              const key = navigation.name;

              if (!partFiltersData || !partFiltersData[key]) {
                if (partItems && partItems.length > 0) {
                  return partItems.map((item) => renderPartItem(item));
                }

                return '';
              }

              return Object.keys(partFiltersData[key]).map((val) => {
                return (
                  <p key={key + val} onClick={() => handleFiltering({ [key]: val }, true)}>
                    {val}
                  </p>
                );
              });
            })}
          {!isLoading &&
            !selectedNavigations &&
            !isEmpty(parsedSearch) &&
            partItems &&
            partItems.map((item) => renderPartItem(item))}
          {!isLoading && partItems && partItems.length === 0 && <div>{label404}</div>}
        </div>
      );
    }

    if (isShowAll || isMultipleFilterExist(parsedSearch)) {
      contentWrapper = (
        <div className="content-wrapper">
          {isLoading && <div>Please wait...</div>}
          {!isLoading && partItems && partItems.map((item) => renderPartItem(item))}
          {!isLoading && partItems && partItems.length === 0 && <div>{label404}</div>}
        </div>
      );
    }

    const renderShowAllButton = isShowAll ? (
      <span className="btn-nav" onClick={() => showAllPartItems(false)}>
        {getShowAllButton()}
      </span>
    ) : (
      <span className="btn-nav" onClick={() => showAllPartItems()}>
        {getShowAllButton()}
      </span>
    );

    return (
      <div key={idx} className="navigation-wrapper">
        <div className="title-wrapper">
          <p>
            Select{' '}
            <span>
              {getFilterName()} ({totalItems})
            </span>
            {!isEmpty(parsedSearch) && !isShowAll && (
              <span className="btn-nav" onClick={() => navigateBack()}>
                Navigate Back
              </span>
            )}
            {partItems && partItems.length > 0 && renderShowAllButton}
          </p>
          <p>
            {!isEmpty(parsedSearch) && (
              <span className="btn-nav" onClick={() => resetFilters()}>
                Reset Parameters
              </span>
            )}
            <span className="btn-nav" onClick={() => setShowParametricSearch(true)}>
              Parametric Filter
            </span>
            Page {currentPage} of {totalPage}
          </p>
        </div>

        {contentWrapper}
      </div>
    );
  };

  const navigationCarousel = [];

  for (let i = 1; i <= totalPage; i++) {
    navigationCarousel.push(renderNavigation(i));
  }
  // End Carousel Section

  return (
    <div ref={innerRef} className={`wizard-header-add-component ${totalPage === 1 ? 'full' : ''}`}>
      {showParametricSearch && (
        <WizardHeaderParametricSearch
          filters={parsedSearch}
          handleFiltering={handleFiltering}
          modalCloseHandler={() => setShowParametricSearch(false)}
          selectedPartName={partForSearch}
          showModal={showParametricSearch}
          noDefaultFilterField
        />
      )}
      <div className="wizard-header-add-component__left">
        <Select
          defaultValue={partForSearch}
          style={{ width: 215 }}
          onChange={(value) => setPartForSearch(value)}
        >
          {parts &&
            parts.map((part) => (
              <Select.Option key={part.name} value={part.name}>
                {part.display_name}
              </Select.Option>
            ))}
        </Select>
        <WizardHeaderSearch />
      </div>

      <Carousel {...carouselProps}>{navigationCarousel}</Carousel>
    </div>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    state,
    parts: getParts(state) || [],
    isLoading: isStateLoading(state.action, 'tenantConfig', 'partItems', 'partFilters'),
  };
};

const mapDispatchToProps = {
  wizardAddComponent,
};

export default connect(mapStateToProps, mapDispatchToProps)(WizardHeaderAddComponent);
