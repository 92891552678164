import { Reducer } from 'redux';
import { keyBy } from 'lodash';

import { ConfigsState } from '.';

import { TenantConfig } from '~/api/API';
import { ApiRequest, ApiSuccess } from '~/store/actions/Api';
import { TENANT_CONFIG } from '~/constants';

export const initialConfigState: ConfigsState = {
  engineeringDrawing: {},
  schematics: {},
};

export const configsReducer: Reducer<ConfigsState> = (
  state: ConfigsState = initialConfigState,
  action: ApiRequest | ApiSuccess,
) => {
  switch (action.type) {
    case `${TENANT_CONFIG}_SUCCESS`: {
      let tenantConfigParts: TenantConfig | undefined = undefined;
      tenantConfigParts = TenantConfig.createFrom((action as ApiSuccess).data);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const newState: any = {
        engineeringDrawing: {},
        schematics: {},
      };
      const constructTenantParts = keyBy(tenantConfigParts.parts, 'name');

      Object.keys(constructTenantParts).map((part) => {
        const {
          engineering_drawing_configs: engineeringDrawingConfigs,
          schematics_configs: schematicsConfigs,
        } = constructTenantParts[part];

        if (part && engineeringDrawingConfigs) {
          newState.engineeringDrawing[part] = [...engineeringDrawingConfigs];
        }

        if (part && schematicsConfigs) {
          newState.schematics[part] = [...schematicsConfigs];
        }

        return part;
      });

      return {
        ...newState,
      };
    }
    default:
      return state;
  }
};
