import React, { useEffect, useState, ReactElement, useCallback } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { BezierCurve, Rectangle } from '../MxGraph/EngineeringDrawing';
import { GraphProps } from '../MxGraph/Graph/Types';
import connectComponents from '../MxGraph/Hoc/connectComponents';
import { Cable, Connector, SplitJunction, Penetrator, Instrument } from '../MxGraph/Schematics';

import { AppState } from '~/store/reducers';
import { ObjectShape, PartConfig } from '~/store/reducers/configs';

type PropsFromState = {
  configs: AppState['configs'];
};

type ComponentsProps = Pick<GraphProps, 'graph'> & PropsFromState;

const Components = (props: ComponentsProps) => {
  const { configs } = props;
  const [components, setComponents] = useState<ReactElement[]>([]);

  const getComponentByShape = useCallback((shape: string, type: string, objects: ObjectShape[]) => {
    // console.log('call step 3 before connect component ');

    switch (shape) {
      case 'rectangle':
        return connectComponents(Rectangle, type, objects);
      case 'bezier_curve':
        return connectComponents(BezierCurve, type, objects);
      case 'connector':
        return connectComponents(Connector, type, objects);
      case 'cable':
        return connectComponents(Cable, type, objects);
      case 'split_junction':
        return connectComponents(SplitJunction, type, objects);
      case 'penetrator':
        return connectComponents(Penetrator, type, objects);
      case 'instrument':
        return connectComponents(Instrument, type, objects);
      default:
        return null;
    }
  }, []);

  const getComponents = useCallback(
    (configs: PartConfig, props: ComponentsProps) => {
      const loadedComponents: ReactElement[] = [];

      Object.keys(configs).forEach((partName) => {
        const parts = configs[partName];

        parts.forEach((part) => {
          // Only call `getComponentByShape` if components are not the same
          // if (!_.isEqual(component, dbcomponent)) {
          const Component = getComponentByShape(part.shape, partName, part.objects);

          if (Component !== null) {
            loadedComponents.push(<Component key={`${partName}_${part.shape}`} {...props} />);
          }
          // } else {
          //   console.log('Components are identical. Skipping getComponentByShape.');
          // }
        });
      });

      return loadedComponents;
    },
    [getComponentByShape],
  );

  const initialComponent = useCallback(() => {
    // console.log('call step 003 below connect component 003 ');

    if (configs.engineeringDrawing) {
      setComponents((data) => [...data, ...getComponents(configs.engineeringDrawing, props)]);
    }

    if (configs.schematics) {
      setComponents((data) => [...data, ...getComponents(configs.schematics, props)]);
    }
  }, [configs.engineeringDrawing, configs.schematics, props, getComponents]);

  useEffect(initialComponent, [initialComponent]);

  if (!props.graph && components.length === 0) return <React.Fragment />;

  return <React.Fragment>{_.uniqBy(components, 'key')}</React.Fragment>;
};

const mapStateToProps = (state: AppState) => {
  return {
    configs: state.configs,
    // component: state.assemblyWizard.components,
    // dbcomponent: state.assemblyWizard.dbComponents,
  };
};

export default connect(mapStateToProps)(React.memo(Components));
