import { Table } from 'antd';
import { useEffect, useState } from 'react';
import { getApprovalHistory } from '~/api/AuthorizedGets';
import { useAppSelector } from '~/store/hooks';

import styles from './index.module.scss';
import Title from 'antd/lib/typography/Title';
import moment from 'moment';
import CustomerApprovals from './CustomerApprovals';

const WizardApprovalHistory = () => {
  const assemblyId = useAppSelector((state) => state.assemblyWizard.id);
  const tenantConfig = useAppSelector((state) => state.data.tenantConfig.data);
  const UIConfig = tenantConfig?.ui_config;

  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    const getData = async (id: number) => {
      const response = await getApprovalHistory(id);
      setData(response.data);
    };

    if (assemblyId) {
      getData(assemblyId);
    }
  }, [assemblyId]);

  const columns = [
    {
      title: 'Action By',
      dataIndex: 'action_by_name',
      key: 'action_by',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
    },
    {
      title: 'Date',
      dataIndex: 'action_occurred_at',
      key: 'action_occurred_at',
      render: (date: string) => moment(date).format('LLL'),
    },
    {
      title: 'New Status',
      dataIndex: 'new_status',
      key: 'new_status',
      render: (status: string) =>
        UIConfig?.assembly_status_mapping
          ? UIConfig?.assembly_status_mapping[status] || status
          : status,
    },
  ];

  return (
    <>
      <CustomerApprovals />
      <div className={styles.wrapper}>
        <Table
          title={() => <Title level={2}>Internal Approvals</Title>}
          columns={columns}
          dataSource={data}
        />
      </div>
    </>
  );
};

export default WizardApprovalHistory;
