import React from 'react';

import { GraphProps } from '../../Graph/Types';
import { ComponentData } from '../../Types';

import Draw from './Draw';

import { ObjectShape } from '~/store/reducers/configs';

type InstrumentProps = {
  data: ComponentData;
  objects: ObjectShape[];
  splitValue: number;
  parentid: any;
  labelname: any;
} & Pick<GraphProps, 'graph'>;

const Instrument = ({ splitValue, labelname, parentid, graph, data, objects }: InstrumentProps) => {
  const drawProps = {
    graph,
    data,
    objects,
    splitValue,
    parentid,
    labelname,
  };

  return (
    <React.Fragment>
      <Draw {...drawProps} />
    </React.Fragment>
  );
};

export default Instrument;
