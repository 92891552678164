const Constant = {
  baseColor: '#000',
  fontSize: '14',
  containerRootId: 'cable',
  cableContainerId: {
    standard: 'standard',
    drain: 'drain',
    shieldedTP: 'shielded_tp',
    shieldedStandard: 'shielded_standard',
    twistedPair: 'twisted_pair',
    coax: 'coax',
    shieldedCoax: 'shielded_coax',
    fiberOptic: 'fiber_optic',
    shieldedFiberOptic: 'shielded_fiber_optic',
    quadTwisted: 'quad_twisted',
    quintupletTwisted: 'quintuplet_twisted',
    shieldedQuadTwisted: 'shielded_quad_twisted',
    triTwisted: 'tri_twisted',
    shieldedTriTwisted: 'shielded_tri_twisted',
  },
  cablePinId: 'cable_pin',
  labelPinId: 'label_pin',
  portLeftId: 'port_left',
  portLeftOnTopId: 'port_left_on_top',
  portLeftOnBottomId: 'port_left_on_bottom',
  portRightOnTopId: 'port_right_on_top',
  portRightOnBottomId: 'port_right_on_bottom',
  portLeftInsideId: 'port_left_inside',
  portRightInsideId: 'port_right_inside',
  portLeftOutsideId: 'port_left_outside',
  portRightOutsideId: 'port_right_outside',
  portLeftAId: 'port_left_a_id',
  portRightAId: 'port_right_a_id',
  portLeftBId: 'port_left_b_id',
  portRightBId: 'port_right_b_id',
  portRightId: 'port_right',
  portTopId: 'port_top',
  portVerticalTopId: 'port_top_vertical',
  portBottomId: 'port_bottom',
  twistedPairTopId: 'twisted_top',
  twistedPairBottomId: 'twisted_bottom',
  bezierSmallEllipseId: 'small_ellipse',
  bigEllipseId: 'big_ellipse',
  leftEllipseId: 'left_ellipse',
  lineUnderEllipseId: 'line_under_ellipse',
  cableQuadTwistedShapeId: 'cable_quad_twisted',
  cablePinHeight: {
    standard: 30,
    drain: 30,
    fiberOptic: 30,
    shieldedFiberOptic: 30,
    shieldedTP: 60,
    shieldedStandard: 30,
    shieldedCoax: 30,
    shieldedQuadTwisted: 30,
    triTwisted: 30,
    shieldedTriTwisted: 30,
  },
  cableContainerWidth: 80,
  labelPin: {
    width: 64,
    height: 10,
  },
};
export default Constant;
