import { mxCell, mxGraph } from '@anekonnect/mxgraph';
import React, { useCallback, useEffect } from 'react';

import { ComponentData } from '../../Types';
import { resizeImageByHeight } from '../../Helper';

import { mx } from '~/constants/wizard';
import { ObjectShape } from '~/store/reducers/configs';
import useGetClickedPostion from '~/hooks/useGetClickedPostion';

type DrawProps = {
  graph: mxGraph;
  data: ComponentData;
  objects: ObjectShape[];
};

const Draw = ({ graph, data: rectangleData, objects }: DrawProps) => {
  const { defaultAlias, files, type, index, id } = rectangleData;
  const clickedPostion = useGetClickedPostion();

  const drawRectangle = useCallback(
    (parent: mxCell) => {
      let firstTime = 1;
      objects.forEach((object, i) => {
        const configs = object.configs;
        const rectangleId = `${type}_ed_${id}_${index}_rectangle_${object.type}`;
        let file = {
          url: object.default_value,
        };

        const found = graph.getModel().getCell(rectangleId);

        if (found) {
          return;
        }

        if (files) {
          file = files.find((file: { type: string }) => file.type === object.type);

          if (!file) {
            file = {
              url: object.default_value,
            };
          }
        }

        if (file) {
          const img = new Image();
          img.src = file.url;
          img.onload = () => {
            const { width, height } = resizeImageByHeight(img, 100);

            const doc = mx.mxUtils.createXmlDocument();
            const node = doc.createElement('component');
            node.setAttribute('label', defaultAlias);
            node.setAttribute('configs', JSON.stringify(configs));

            const draw = graph.insertVertex(
              parent,
              rectangleId,
              node,
              firstTime === 1 ? clickedPostion.x - 50 : clickedPostion.x - 50 + i * 160,
              clickedPostion.y,
              width,
              height,
              `shape=image;image=${file.url};aspect=fixed;`,
            );
            firstTime = 2;

            draw.geometry.offset = new mx.mxPoint(0, -height / 2 - 27);

            draw.setConnectable(configs.connectable);
            mx.mxVertexHandler.prototype.rotationEnabled = configs.rotateable;

            graph.refresh(draw);
          };
        }
      });
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [objects, type, id, index, graph, files, defaultAlias],
  );

  const addRectangle = useCallback(() => {
    const { mxClient, mxUtils } = mx;

    if (!mxClient.isBrowserSupported) {
      mxUtils.error('Browser is not supported!', 200, false);
    } else {
      const parent = graph.getDefaultParent();
      graph.getModel().beginUpdate();

      try {
        if (id) {
          drawRectangle(parent);
        }
      } finally {
        graph.getModel().endUpdate();
      }
    }
  }, [graph, drawRectangle, id]);

  useEffect(addRectangle, [addRectangle]);

  const handleFixedPoints = useCallback(() => {
    const { mxPoint, mxConnectionConstraint, mxImage, mxConstraintHandler, mxConstants } = mx;

    const getAllConnectionConstraints = graph.getAllConnectionConstraints;

    // Set fixed points for vertex
    graph.getAllConnectionConstraints = function (terminal) {
      terminal.setCursor(mxConstants.CURSOR_TERMINAL_HANDLE);

      const selectedCell = graph.getSelectionCell();
      const isEdge = graph.model.isEdge(selectedCell);

      if (terminal && isEdge) {
        const isRectangle = terminal.cell.id.includes('rectangle');

        if (!isRectangle) {
          return [];
        }

        const connectable = terminal.cell.connectable;

        if (connectable) {
          mxConstraintHandler.prototype.pointImage = new mxImage('/img/point.gif', 5, 5);

          const { width, height } = terminal.cell.geometry;

          const pixelWidth = 1 / width;
          const pixelHeight = 1 / height;
          const connectionPoints = [];

          for (let i = 0; i <= width; i++) {
            if (i % 7 === 0) {
              connectionPoints.push(
                new mxConnectionConstraint(new mxPoint(pixelWidth * i, 0), true),
              );
              connectionPoints.push(
                new mxConnectionConstraint(new mxPoint(pixelWidth * i, 1), true),
              );
            }
          }

          for (let i = 0; i <= height; i++) {
            if (i % 7 === 0) {
              connectionPoints.push(
                new mxConnectionConstraint(new mxPoint(0, pixelHeight * i), true),
              );
              connectionPoints.push(
                new mxConnectionConstraint(new mxPoint(1, pixelHeight * i), true),
              );
            }
          }

          return connectionPoints;
        }
      }

      return getAllConnectionConstraints.apply(this, [terminal]);
    };
  }, [graph]);

  useEffect(handleFixedPoints, [handleFixedPoints]);

  const handleLabelPositionOnResize = useCallback(() => {
    graph.addListener(mx.mxEvent.CELLS_RESIZED, (_sender: any, evt: any) => {
      const { cells } = evt.properties;
      const { mxGeometry } = mx;

      cells.forEach((cell: any) => {
        if (cell.id.includes('rectangle')) {
          const { geometry } = cell;

          if (geometry instanceof mxGeometry) {
            const { height } = geometry;
            const { x } = geometry.offset;

            geometry.offset = new mx.mxPoint(x, -height / 2 - 27);
          }
        }
      });
    });
  }, [graph]);

  useEffect(handleLabelPositionOnResize, [handleLabelPositionOnResize]);

  return <React.Fragment />;
};

export default Draw;
