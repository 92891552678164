import { JSONSchema7 } from 'json-schema';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import { FormSchema } from '~/components/FormSchema/FormSchema';
import { wizardSetRevisionsData } from '~/store/actions/wizard/Action';
import { AppState } from '~/store/reducers';
import { Revisions, Revision } from '~/store/reducers/wizard/State';

type PropsFromState = {
  revisions: Revisions;
  revisionsTableInputSchema: string;
};

type PropsFromDispatch = {
  wizardSetRevisionsData: typeof wizardSetRevisionsData;
};

type WizardRevisionsProps = PropsFromState & PropsFromDispatch;

type FormData = {
  formData: {
    revisions: Revision[];
  };
};

const WizardRevisions = ({
  revisions,
  revisionsTableInputSchema,
  wizardSetRevisionsData,
}: WizardRevisionsProps) => {
  const [data, setData] = useState<Revision[]>(revisions.data ?? []);

  const handleConfirmAction = (data: FormData) => {
    const formData: Revision[] = [...data.formData.revisions];
    setData(formData);
    wizardSetRevisionsData(formData);
  };

  const handleChange = (data: FormData) => {
    const formData: Revision[] = [...data.formData.revisions];
    setData(formData);
    wizardSetRevisionsData(formData);
  };

  const renderForm = () => {
    const schema: JSONSchema7 = JSON.parse(revisionsTableInputSchema);
    const formData = { revisions: data || [] };

    return (
      <FormSchema
        cancelAction={() => console.log('cancel call')}
        confirmAction={(data) => handleConfirmAction(data)}
        formData={formData}
        schema={schema}
        showActions={false}
        onChange={(data) => handleChange(data)}
      />
    );
  };

  return <div className="wizard_revisions">{renderForm()}</div>;
};

const mapStateToProps = (state: AppState) => {
  // console.log('state', state);
  return {
    revisions: state.assemblyWizard.revisions,
    revisionsTableInputSchema: state.data.tenantConfig.data?.revisions_table_config_input,
  };
};

const mapDispatchToProps = {
  wizardSetRevisionsData,
};

export default connect(mapStateToProps, mapDispatchToProps)(WizardRevisions);
