import React, { useEffect } from 'react';
import { mxGraph as mxGraphTypeState } from '@anekonnect/mxgraph';

import Graph from './Graph';
import { GraphEventsProps, GraphModalsProps, GraphProps } from './Graph/Types';

import { mx } from '~/constants/wizard';
import { useDispatch } from 'react-redux';
import { setPosition } from '~/store/reducers/postion/Postion';

type BaseProps = {
  forwardedRef: React.MutableRefObject<null>;
  onAction: (graph: mxGraphTypeState, container: HTMLElement) => void;
  children?: React.ReactNode;
};

type JSXAttrProps = JSX.IntrinsicAttributes &
  React.ClassAttributes<HTMLDivElement> &
  React.HTMLAttributes<HTMLDivElement>;

type MxContainerProps = BaseProps &
  JSXAttrProps & {
    container: HTMLElement | undefined;
  } & GraphProps &
  GraphModalsProps &
  GraphEventsProps;

const MxGraph = ({
  children,
  forwardedRef,
  onAction,
  container,
  graphRefs,
  graph,
  graphForms,
  graphVisibles,
  graphSetVisibles,
  componentContainerModal,
  activeTabKey,
  currentCell,
  setCurrentCell,
  ...restProps
}: MxContainerProps) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const { mxClient, mxUtils, mxEvent, mxGraph, mxRubberband } = mx;

    if (!mxClient.isBrowserSupported) {
      mxUtils.error('Browser is not supported!', 200, false);
    } else {
      const container = forwardedRef.current as unknown as HTMLElement;
      let isSVGExist = false;

      // Check if graph SVG already exist
      container.childNodes.forEach((node) => (isSVGExist = node.nodeName === 'svg'));

      if (container && !isSVGExist) {
        // Disables the built-in context menu
        mxEvent.disableContextMenu(container);

        // // Creates the graph inside the given container
        const initialGraph = new mxGraph(container);

        // Enables rubberband selection
        new mxRubberband(initialGraph);

        // Add your config here
        onAction(initialGraph, container);
      }
    }
  }, [onAction, forwardedRef]);

  const graphProps = {
    container,
    graphRefs,
    graph,
    graphForms,
    graphVisibles,
    graphSetVisibles,
    componentContainerModal,
    activeTabKey,
    currentCell,
    setCurrentCell,
  };
  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const div = e.currentTarget; // The div that was clicked
    const divRect = div.getBoundingClientRect(); // Get the div's position and size

    // Calculate the clicked position relative to the div
    let clickedX = e.clientX - divRect.left;
    let clickedY = e.clientY - divRect.top + div.scrollTop;

    if (clickedX <= 150) {
      clickedX = 150;
    }
    if (clickedY <= 150) {
      clickedY = 150;
    }

    dispatch(setPosition(clickedX, clickedY));
  };

  return (
    <div {...restProps} ref={forwardedRef} className="mxContainer" onClick={handleClick}>
      <Graph {...graphProps}>{children || null}</Graph>
    </div>
  );
};

export default MxGraph;
