import { JSONSchema7 } from 'json-schema';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import { FormSchema } from '~/components/FormSchema/FormSchema';
import { wizardSetCustomerApprovalData } from '~/store/actions/wizard/Action';
import { AppState } from '~/store/reducers';
import { CustomerApprovals, CustomerApproval } from '~/store/reducers/wizard/State';
import styles from './index.module.scss';

type PropsFromState = {
  customerApprovals: CustomerApprovals;
  customerApprovalTableInputSchema: string;
};

type PropsFromDispatch = {
  wizardSetCustomerApprovalData: typeof wizardSetCustomerApprovalData;
};

type WizardCustomerApprovalProps = PropsFromState & PropsFromDispatch;

type FormData = {
  formData: {
    customerApprovals: CustomerApproval[];
  };
};

const WizardCustomerApproval = ({
  customerApprovals,
  customerApprovalTableInputSchema,
  wizardSetCustomerApprovalData,
}: WizardCustomerApprovalProps) => {
  const [data, setData] = useState<CustomerApproval[]>(customerApprovals.data ?? []);

  const handleConfirmAction = (data: FormData) => {
    const formData: CustomerApproval[] = [...data.formData.customerApprovals];
    setData(formData);
    wizardSetCustomerApprovalData(formData);
  };

  const handleChange = (data: FormData) => {
    const formData: CustomerApproval[] = [...data.formData.customerApprovals];
    setData(formData);
    wizardSetCustomerApprovalData(formData);
  };

  const renderForm = () => {
    const schema: JSONSchema7 = JSON.parse(customerApprovalTableInputSchema);
    const formData = { customerApprovals: data || [] };

    return (
      <>
        <h2 className={styles.external_approvals_titel}>External Approvals</h2>
        <FormSchema
          cancelAction={() => console.log('cancel call')}
          confirmAction={(data) => handleConfirmAction(data)}
          formData={formData}
          schema={schema}
          showActions={false}
          onChange={(data) => handleChange(data)}
        />
      </>
    );
  };

  return <div>{renderForm()}</div>;
};

const mapStateToProps = (state: AppState) => {
  return {
    customerApprovals: state.assemblyWizard.customerApprovals,
    customerApprovalTableInputSchema: state.data.tenantConfig.data?.customer_approvals_config_input,
  };
};

const mapDispatchToProps = {
  wizardSetCustomerApprovalData,
};

export default connect(mapStateToProps, mapDispatchToProps)(WizardCustomerApproval);
