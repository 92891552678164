import { mxCell, mxGraph } from '@anekonnect/mxgraph';
import { useEffect } from 'react';
import { connect } from 'react-redux';

import { mx } from '~/constants/wizard';
import { wizardSetCustomerApprovalsPosition } from '~/store/actions/wizard/Action';
import { AppState } from '~/store/reducers';
import { CustomerApproval, Point } from '~/store/reducers/wizard/State';
import { BottomToTopTableTemplate, TopToBottomTableTemplate } from './templates';

type PropsFromState = {
  customerApprovalsData: CustomerApproval[];
  customerApprovalsPosition: Point;
  customerApprovalsTableConfig: string;
};

type PropsFromDispatch = {
  wizardSetCustomerApprovalsPosition: typeof wizardSetCustomerApprovalsPosition;
};

type CustomerApprovalsTableProps = {
  graph: mxGraph;
} & PropsFromState &
  PropsFromDispatch;

const CustomerApprovalsTable = ({
  graph,
  customerApprovalsData,
  customerApprovalsPosition,
  customerApprovalsTableConfig,
  wizardSetCustomerApprovalsPosition,
}: CustomerApprovalsTableProps) => {
  const tablePrefix = 'customer-approvals-table';

  // Save last position of Revisions table
  useEffect(() => {
    graph.addListener(mx.mxEvent.CELLS_MOVED, (_sender, evt) => {
      const cells = evt.properties.cells;

      if (cells.length > 0) {
        cells.forEach((cell: mxCell) => {
          if (cell.id === tablePrefix) {
            wizardSetCustomerApprovalsPosition(cell.getGeometry());
          }
        });
      }
    });
  }, [graph, wizardSetCustomerApprovalsPosition]);

  if (!customerApprovalsTableConfig) {
    return;
  }

  const tableConfigJson = JSON.parse(customerApprovalsTableConfig);
  const template = tableConfigJson?.table?.template;
  const showTitle =
    tableConfigJson?.title?.showTitle !== undefined ? tableConfigJson?.title?.showTitle : true;

  switch (template) {
    case 'top-to-bottom':
      return (
        <TopToBottomTableTemplate
          graph={graph}
          tablePrefix={tablePrefix}
          tableConfigJson={tableConfigJson}
          data={customerApprovalsData}
          position={customerApprovalsPosition}
          showTitle={showTitle}
        />
      );
    case 'bottom-to-top':
      return (
        <BottomToTopTableTemplate
          graph={graph}
          tablePrefix={tablePrefix}
          tableConfigJson={tableConfigJson}
          data={customerApprovalsData}
          position={customerApprovalsPosition}
          showTitle={showTitle}
        />
      );
    default:
      return (
        <TopToBottomTableTemplate
          graph={graph}
          tablePrefix={tablePrefix}
          tableConfigJson={tableConfigJson}
          data={customerApprovalsData}
          position={customerApprovalsPosition}
          showTitle={showTitle}
        />
      );
  }
};

const mapStateToProps = (state: AppState) => {
  return {
    customerApprovals: state.assemblyWizard.customerApprovals,
    customerApprovalsData: state.assemblyWizard.customerApprovals.data ?? [],
    customerApprovalsPosition: state.assemblyWizard.customerApprovals.position,
    customerApprovalsTableConfig: state.data.tenantConfig.data?.customer_approvals_config_display,
  };
};

const mapDispatchToProps = {
  wizardSetCustomerApprovalsPosition,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerApprovalsTable);
