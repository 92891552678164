import { mxCell, mxGraph } from '@anekonnect/mxgraph';
import React, { useCallback, useEffect, useRef } from 'react';

import { replaceStyles } from '../../Helper';
import { ComponentData } from '../../Types';

import Constant from './Constant';
import { customQuadTwistedShape, customTwistedPairShape } from './Category/CustomShape';
import { BezierCores, PreviousGeo } from './Types';
import {
  standard,
  shieldedTP,
  twistedPair,
  shieldedStandard,
  shieldedCoax,
  fiberOptic,
  shieldedFiberOptic,
  shieldedQuadTwisted,
  shieldedTriTwisted,
  triTwisted,
  coax,
  quadTwisted,
  drain,
  quintupletTwisted,
} from './Category';

import { mx } from '~/constants/wizard';
import { ObjectShape } from '~/store/reducers/configs';

type Props = {
  data: ComponentData;
  graph: mxGraph;
  objects: ObjectShape[];
};

const Draw: React.FC<Props> = (props) => {
  const { graph, data, objects } = props;

  const geometryRef = useRef<PreviousGeo[]>([]);

  const drawCable = useCallback(
    (parent: mxCell) => {
      const bezierDataCores = data.cores as BezierCores[];
      const configs = objects[0].configs;

      if (bezierDataCores && bezierDataCores.length) {
        bezierDataCores.map((dataOfCore, indexOfCore) => {
          const { type } = dataOfCore;

          switch (type) {
            case 'Standard': {
              standard(graph, data, parent, geometryRef, indexOfCore, configs);

              break;
            }
            case 'Drain': {
              drain(graph, data, parent, geometryRef, indexOfCore, configs);

              break;
            }
            case 'ShieldedTP': {
              shieldedTP(graph, data, parent, geometryRef, indexOfCore, configs);

              break;
            }
            case 'TwistedPair': {
              twistedPair(graph, data, parent, geometryRef, indexOfCore, configs);

              break;
            }
            case 'ShieldedStandard': {
              shieldedStandard(graph, data, parent, geometryRef, indexOfCore, configs);

              break;
            }
            case 'Coax': {
              coax(graph, data, parent, geometryRef, indexOfCore, configs);

              break;
            }
            case 'ShieldedCoax': {
              shieldedCoax(graph, data, parent, geometryRef, indexOfCore, configs);

              break;
            }
            case 'FiberOptic': {
              fiberOptic(graph, data, parent, geometryRef, indexOfCore, configs);

              break;
            }
            case 'ShieldedFiberOptic': {
              shieldedFiberOptic(graph, data, parent, geometryRef, indexOfCore, configs);

              break;
            }
            case 'ShieldedQuadTwisted': {
              shieldedQuadTwisted(graph, data, parent, geometryRef, indexOfCore, configs);
              break;
            }
            case 'ShieldedTriTwisted': {
              shieldedTriTwisted(graph, data, parent, geometryRef, indexOfCore, configs);

              break;
            }
            case 'TriTwisted': {
              triTwisted(graph, data, parent, geometryRef, indexOfCore, configs);

              break;
            }
            case 'QuadTwisted': {
              quadTwisted(graph, data, parent, geometryRef, indexOfCore, configs);

              break;
            }
            case 'QuintupletTwisted': {
              quintupletTwisted(graph, data, parent, geometryRef, indexOfCore, configs);
              break;
            }
            default:
              break;
          }

          return type;
        });
      }
    },
    [data, graph, objects],
  );

  const drawShieldedCable = useCallback(
    (data: ComponentData) => {
      const { type, id, index } = data;
      const rootId = `${type}_schematics_${id}_${index}_draw_is_shielded`;

      const found = graph.getModel().getCell(`${rootId}_${Constant.bigEllipseId}`);

      if (found) {
        return found;
      }

      const applyContainerShieldStyles = (container: mxCell) => {
        const newCableContainerStyle = replaceStyles(container, [
          {
            name: 'fillColor',
            value: '#d3d3d3',
          },
          {
            name: 'strokeColor',
            value: 'none',
          },
          {
            name: 'rounded',
            value: '1',
          },
        ]);
        container.setStyle(newCableContainerStyle);
        container.geometry.height = container.geometry.height + 15;
      };

      const { mxPoint } = mx;

      if (geometryRef.current.length > 0) {
        const container = geometryRef.current[0].componentContainer;
        applyContainerShieldStyles(container);

        const bigEllipse = graph.insertVertex(
          container,
          `${rootId}_${Constant.bigEllipseId}`,
          null,
          0,
          0,
          container.geometry.width - 20,
          container.geometry.height - 15,
          `shape=ellipse;align=center;verticalAlign=middle;fillColor=none;strokeColor=#000;dashed=1`,
        );
        bigEllipse.geometry.relative = true;
        bigEllipse.geometry.offset = new mxPoint(
          container.geometry.width / 2 - bigEllipse.geometry.width / 2,
          7.5,
        );
        bigEllipse.setConnectable(false);

        const portVerticalTop = graph.insertVertex(
          container,
          `${rootId}_${Constant.portVerticalTopId}`,
          null,
          0,
          0,
          0.1,
          15,
          `align=center;rounded=0;fontColor=#000;strokeColor=#000;dashed=0;`,
        );
        portVerticalTop.geometry.relative = true;
        portVerticalTop.geometry.offset = new mxPoint(container.geometry.width / 2, -7.5);
        portVerticalTop.setConnectable(true);

        const portBottom = graph.insertVertex(
          container,
          `${rootId}_${Constant.portBottomId}`,
          null,
          0,
          0,
          0.1,
          14,
          `align=center;rounded=0;fontColor=#000;strokeColor=#000;dashed=0;`,
        );
        portBottom.geometry.relative = true;
        portBottom.geometry.offset = new mxPoint(
          container.geometry.width / 2,
          container.geometry.height - 7.5,
        );
        portBottom.setConnectable(true);
      }
    },
    [graph],
  );

  const addCable = useCallback(() => {
    const { mxClient, mxUtils } = mx;

    if (!mxClient.isBrowserSupported) {
      mxUtils.error('Browser is not supported!', 200, false);
    } else {
      const parent = graph.getDefaultParent();
      graph.getModel().beginUpdate();

      // Implements custom shape
      customQuadTwistedShape();
      customTwistedPairShape();

      try {
        if (data) {
          drawCable(parent);

          if (data['is_shielded']) {
            drawShieldedCable(data);
          }
        }
      } finally {
        graph.getModel().endUpdate();
      }
    }
  }, [data, graph, drawCable, drawShieldedCable]);

  useEffect(addCable, [addCable]);

  return <React.Fragment />;
};

export default Draw;
