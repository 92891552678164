import { Modal } from 'antd';
import { useAppSelector } from '~/store/hooks';

type VisibilityModalProps = {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

export const VisibilityModal = ({ isOpen, onCancel, onConfirm }: VisibilityModalProps) => {
  const isPublic = useAppSelector((state) => state.assemblyWizard.isPublic);

  return (
    <Modal
      forceRender
      cancelText="Cancel"
      okText="Confirm"
      title={`Confirm Visibility Settings Change To: ${isPublic ? 'Private' : 'Public'}.`}
      open={isOpen}
      onCancel={onCancel}
      onOk={() => {
        onConfirm();
      }}
    >
      <p>
        {isPublic
          ? 'Assembly will be visible to creator of the drawing and to specific persons you share with.'
          : 'Assembly will be visible to all users in this instance.'}
      </p>
    </Modal>
  );
};
